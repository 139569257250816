import React, { useState, useEffect } from "react";
import RangeSlider from "../../../../components/addon/rangeSlider";

interface Props {
	totalImpression?: any;
	setTotalImpression?: any;
	impression?: any;
	setImpression?: any;
	disable?: any;
	manuallyChangeImpression?: any;
	min?: any;
	max?: any;
	setPageLoading?: any;
	setEventAndInventory?: any;
}

const CampaignImpression = ({
	totalImpression,
	setTotalImpression,
	disable,
	manuallyChangeImpression,
	min,
	max,
	setPageLoading,
	setEventAndInventory,
}: Props) => {
	const [impressionError, setImpressionError] = useState(false);
	const [impression, setImpression] = useState(totalImpression);
	const [isInventoryAndEvent, setIsInventoryAndEvent] =
		useState(setEventAndInventory);

	console.log("isInventoryAndEvent on campaignImpression", isInventoryAndEvent);

	const [timeOutId, setTimeOutId] = useState<any>();
	useEffect(() => {
		setImpression(totalImpression);
	}, [totalImpression]);
	const onRangeChange = (data) => {
		if (!disable) {
			setImpression(data);
			if (timeOutId) {
				console.log("timeOutId", timeOutId);
				clearTimeout(timeOutId);
			}
			let _id = setTimeout(() => {
				manuallyChangeImpression(data);
			}, 1000);
			setTimeOutId(_id);
		}
	};
	const changeError = () => {
		setImpressionError(false);
	};
	return (
		<>
			{!isInventoryAndEvent?.inventory &&
			isInventoryAndEvent?.eventAndOccations ? (
				// ""
				<div
					className="box-design mb-20 overheadThree"
					style={{ display: "none" }}
				>
					<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
						<h2 className="htx3 h-achor clearfix">
							Estimated Campaign Impressions
						</h2>
						<div className="inventory-overHead camp-performance genderSplit">
							<h3>{impression.toLocaleString("en-IN")}</h3>
							{disable !== true && (
								<RangeSlider
									min={min}
									max={max}
									value={impression}
									onSliderValueChange={onRangeChange}
									changeError={changeError}
									disable={disable || false}
								/>
							)}
						</div>
					</div>
				</div>
			) : isInventoryAndEvent?.inventory &&
			  isInventoryAndEvent?.eventAndOccations ? (
				<div className="box-design mb-20 overheadThree">
					<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
						<h2 className="htx3 h-achor clearfix">
							Estimated Campaign Impressions
						</h2>
						<div className="inventory-overHead camp-performance genderSplit">
							<h3>{impression.toLocaleString("en-IN")}</h3>
							{disable !== true && (
								<RangeSlider
									min={min}
									max={max}
									value={impression}
									onSliderValueChange={onRangeChange}
									changeError={changeError}
									disable={disable || false}
								/>
							)}
						</div>
					</div>
				</div>
			) : (
				<div className="box-design mb-20 overheadThree">
					<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
						<h2 className="htx3 h-achor clearfix">
							Estimated Campaign Impressions
						</h2>
						<div className="inventory-overHead camp-performance genderSplit">
							<h3>{impression.toLocaleString("en-IN")}</h3>
							{disable !== true && (
								<RangeSlider
									min={min}
									max={max}
									value={impression}
									onSliderValueChange={onRangeChange}
									changeError={changeError}
									disable={disable || false}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CampaignImpression;
