import {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    Fragment,
    useContext,
    useRef
} from "react"

import config from "../../../../../service/config"

import AppContextObject from "../../../../../common/context/common"
import { PublicContextInitiator } from "../../../../apis/publicContextInitiator"
import TextField from '../../../../../components/formFields/textField/textField'
import Textfield from "../../../../../components/formFields/textField/textField"
import Numberfield from "../../../../../components/formFields/numberField"
import SingleSelect from "../../../../../components/formFields/singleSelect/singleSelect"
import NumWithPlusMinus from "../../../../../components/formFields/numWithPlusMinus/numWithPlusMinus"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"
import ImagePreview from "../../../../../components/addon/imagePreview"

import check from "../../../../../assets/images/check.svg"
import star from "../../../../../assets/images/star.svg"
import tabBlank from "../../../../../assets/images/tab-blank-img.png"
import close from "../../../../../assets/images/close.svg"
import closeBG from "../../../../../assets/images/close-1.svg"
import deleteImg from "../../../../../assets/images/delete.svg"
import addBox from "../../../../../assets/images/add-bx.svg"

import { duplicateInventoryName, objectDeepClone, titleCase } from "../../../../../service/helper"

import Compress from "compress.js"
import DataContext from "../../../../../service/contextProvider"
import { Steps } from "intro.js-react"
import errorSVG from "../../../../../assets/images/error.svg"
import SimpleReactValidator from "simple-react-validator"

interface Props {
    amenityList?: any
    setIsSubmitDisabled?: Function
    onCustomAmenityAdd?: Function
    onCustomAmenityDelete?: Function
    updateCompleteState?: boolean
    isEdit?: boolean
    configData?: any
    openRemainingInventories?: Function
    onAmenitiesChange?: Function,
    inventoryType?: any,
    onBoarding?: any,
    inAddInventory?: boolean,
    hideRevenue?: boolean,
    updateParentErrorMsg?: Function,
    inDashboard?: boolean
}

const AmenityDetails = forwardRef(({
    amenityList,
    setIsSubmitDisabled,
    onCustomAmenityAdd,
    onCustomAmenityDelete,
    updateCompleteState,
    isEdit,
    openRemainingInventories,
    onAmenitiesChange,
    inAddInventory,
    inventoryType,
    configData,
    onBoarding,
    hideRevenue,
    updateParentErrorMsg,
    inDashboard
}: Props, ref) => {

    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })
    ))
    const [update, forceUpdate] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [selectedAmenity, setSelectedAmenity] = useState(null)
    const [selectedAmenityDetailIdx, setSelectedAmenityDetailIdx] = useState(null)
    const [selectedAmenityDetail, setSelectedAmenityDetail] = useState(null)
    const [amenities, setAmenities] = useState(objectDeepClone(amenityList))
    const [initialAmenities]: any = useState(JSON.stringify(amenityList))
    const [customAttr, setCustomAttr] = useState({ attributeName: "" })
    const [showNewAttrTextfield, setShowNewAttrTextfield] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [quantityList, setQuantityList] = useState([])
    const [selectedAttrForEdit, setSelectedAttrForEdit] = useState("")
    const [addCTA, setAddCTA] = useState("+ Add")
    const [toggleAmenityDeleteConfirm, setToggleAmenityDeleteConfirm] = useState(false)
    const [toggleMainAmenityDeleteConfirm, setToggleMainAmenityDeleteConfirm] = useState(false)
    const [selectedMainAmenityName, setSelectedMainAmenityToDelete] = useState("")
    const [selectedMainAmenityToDeleteIdx, setSelectedMainAmenityToDeleteIdx] = useState(0)
    const [toggleCustomAttrDeleteConfirm, setToggleCustomAttrDeleteConfirm] = useState(false)
    const [selectedDeleteAmenityIndex, setSelectedDeleteAmenityIndex] = useState(null)
    const [selectedDeleteAmenityName, setSelectedDeleteAmenityName] = useState(null)
    const [selectedDeleteAmenityDetails, setSelectedDeleteAmenityDetails] = useState(null)
    const [selectedCustomAttrName, setSelectedCustomAttrName] = useState(null)
    const [selectedAttr, setSelectedAttr] = useState("stage")
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [amenityDetailList, setAmenityDetailList] = useState([])
    const [completedAmenities, setCompletedAmenities] = useState([])
    const [imagesArray, setImagesArray] = useState([])
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [quantityErrorMessage, setQuantityErrorMessage] = useState("Please fill all data for");
    const [imageBase64String, setImageBase64String] = useState("")
    const [removeImage, setRemoveImage] = useState(null);
    const [backupCount, setBackupCount] = useState(0)
    const [amenityCount, setAmenityCount] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [imagesCount, setImagesCount] = useState([]);
    const [attributeError, setAttributeError] = useState(false)
    // const [sameAttributeNameError,setSameAttributeNameError] = useState(false)
    const [attributeErrorMsg, setAttributeErrorMsg] = useState("")
    const [notUploadedImagesList, setNotUploadedImagesList] = useState([])
    const [toggleImageLoadingPopUp, setToggleImageLoadingPopUp] = useState(false)

    const [validatorObj, setValidatorObj] = useState({})
    const [currentErrorObj, setCurrentErrorObj] = useState([])
    const [showQuantityError, setShowQuantityError] = useState(false)
    const [quantityError, setQuantityError] = useState(false)
    const [showSizeError, setShowSizeError] = useState(false)
    const [sizeError, setSizeError] = useState(false)
    const [aminityDeleteInProgress, setAminityDeleteInProgress] = useState(false)
    const [aminityAttrError, setAminityAttrError] = useState("")
    const [tempData, setTempData] = useState({
        quantity: 1,
        selectedItem: ""
    })

    const [defaultAttrs] = useState({
        quantity: 0,
        units: "feet",
        length: 0,
        height: 0,
        breadth: 0,
    })
    const [defaultObj] = useState({
        name: "",
        images: [],
        customName: "",
        attr: {},
        count: selectedAmenityDetail && selectedAmenityDetail.count || backupCount
    })
    const [onBoarding2, setOnboarding2] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            { element: "#addMore", intro: "Add all relevent information about the inventory here.", position: "right" },
            { element: "#addImages", intro: "Click here to add images of this particular invertory. Make sure you add atleast 5 images.", position: "right" },
            { element: "#addNewAttribute", intro: "If you wish to add more inventory placements. Click here and add details again.", position: "right" },
            { element: ".tabset", intro: "It is important to add all information here to help brands create the campaigns with ease.", position: "right" }
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: false,
            disableInteraction: true
        },
    })
    const [notSupportedImageErr, setNotSupportedImageErr] = useState("")

    const compress = new Compress()

    useImperativeHandle(ref, () => ({
        fetchAllAmenities() {
            return amenities
        },
        fetchSelectedAminity() {
            return selectedAmenity
        },

        fetchValidation() {
            return new Promise((resolve) => {
                validateData().then(response => {
                    resolve(response)
                })
            })
        },

        resetAmenityDetail() {
            setSelectedAmenity(null)
        }
    }))

    useEffect(() => {
        validateSelectedAmenity()
    }, [selectedAmenity, validatorObj])

    useEffect(() => {
        setAmenities([...amenityList])
        if (amenities.length > amenityList.length) { // This means one of the selected amenity is deleted or deselected.
            setSelectedAmenity(null)
        }
        if (updateCompleteState === true) {
            let completedList = []
            console.log("first", amenities)
            amenityList.forEach((el, i) => {
                let isEmpty = false
                let quantityNotZero = []
                let lbhNotZero = []
                el.data.forEach((d, index) => {
                    if (isEdit) {
                    } else {
                        setImagesArray(d.images)
                    }
                    let emptyCount = 0;
                    Object.keys(amenityList[i].data[index].attr).forEach(a => {
                        Object.keys(amenityList[i].data[index].attr[a]).forEach(attr => {
                            if (isEmpty === false && (!amenityList[i].data[index].attr[a][attr])) { // || amenityList[i].data[index].attr[a][attr] === "0"
                                if (inDashboard) {
                                    console.log("useEffect attr-->>", attr, isEdit);
                                    if (attr === "quantity" || attr === "length" || attr === "height" || attr === "breadth") {
                                        if ((amenityList[i].data[index].attr[a][attr] === "")) {
                                            isEmpty = true
                                        }
                                    }
                                } else {
                                    if (attr === "quantity" || attr === "length" || attr === "height" || attr === "breadth") {
                                        if ((amenityList[i].data[index].attr[a][attr] === "")) {
                                            isEmpty = true
                                        }
                                    }
                                }


                            }

                            // if((amenityList[i].data[index].attr[a]["quantity"] === "0" ||  amenityList[i].data[index].attr[a]["quantity"] === 0) && ((amenityList[i].data[index].attr[a]["length"] !== "0" ||  amenityList[i].data[index].attr[a]["length"] > 0) || (amenityList[i].data[index].attr[a]["height"] !== "0" ||  amenityList[i].data[index].attr[a]["height"] > 0) || (amenityList[i].data[index].attr[a]["breadth"] !== "0" ||  amenityList[i].data[index].attr[a]["breadth"] > 0)) && isEdit === true){
                            //     lbhNotZero.push(true)
                            // }
                            // else{
                            //     lbhNotZero.push(false)
                            // }
                            // if((amenityList[i].data[index].attr[a]["quantity"] !== "0" ||  amenityList[i].data[index].attr[a]["quantity"] > 0) && ((amenityList[i].data[index].attr[a]["length"] === "0" ||  amenityList[i].data[index].attr[a]["length"] === 0) && (amenityList[i].data[index].attr[a]["height"] === "0" ||  amenityList[i].data[index].attr[a]["height"] === 0) && (amenityList[i].data[index].attr[a]["breadth"] === "0" ||  amenityList[i].data[index].attr[a]["breadth"] === 0)) && isEdit === true){
                            //     quantityNotZero.push(true)
                            // }
                            // else{
                            //     quantityNotZero.push(false)
                            // }
                            // if (!attrList.find(l => l === a)) attrList = [...attrList, a]
                        })
                        if (amenities[i]) {
                            if ((Number(amenities[i].data[index].attr[a]["quantity"]) > 0) && (Number(amenities[i].data[index].attr[a]["length"]) === 0) && (Number(amenities[i].data[index].attr[a]["breadth"]) === 0) && (Number(amenities[i].data[index].attr[a]["height"]) === 0)) {
                                isEmpty = true
                                console.log("condition satisfied", isEmpty);
                                // if (!attrList.find(l => l === a)) attrList = [...attrList, a]
                            }

                            if ((Number(amenities[i].data[index].attr[a]["quantity"]) === 0) && ((Number(amenities[i].data[index].attr[a]["length"]) > 0) || (Number(amenities[i].data[index].attr[a]["breadth"]) > 0) || (Number(amenities[i].data[index].attr[a]["height"]) > 0))) {
                                isEmpty = true
                                console.log("condition satisfied", isEmpty);
                                // if (!attrList.find(l => l === a)) attrList = [...attrList, a]
                            }
                            if (Number(amenities[i].data[index].attr[a]["quantity"]) === 0 && Number(amenities[i].data[index].attr[a]["length"]) === 0 && Number(amenities[i].data[index].attr[a]["breadth"]) === 0 && Number(amenities[i].data[index].attr[a]["height"]) === 0) {
                                emptyCount++;
                            }
                        }
                    })
                    // console.log("Object.keys(amenities[i].data[index].attr).length", Object.keys(amenities[i]?.data[index]?.attr)?.length, emptyCount)
                    if (amenities[i] && emptyCount === Object.keys(amenities[i]?.data[index]?.attr).length) {
                        isEmpty = true
                    }
                })
                if (isEmpty === false) {
                    completedList.push(el.name)
                }
            })
            setCompletedAmenities(completedList)
        }
        if (amenities.length > 0) {
            setActiveAmenity(0)
        }

    }, [amenityList])
    console.log("imagesArray--->>", imagesArray);

    useEffect(() => {
        const arr = objectDeepClone(amenities)
        if (arr[selectedAmenity]) {
            arr[selectedAmenity].data[selectedAmenityDetailIdx] = objectDeepClone(selectedAmenityDetail)
            setAmenities(arr)
        }
        console.log("selectedAmenityDetail>>>>", selectedAmenityDetail);
        setImagesArray(selectedAmenityDetail && selectedAmenityDetail.images && selectedAmenityDetail.images)
        setNotSupportedImageErr("")
    }, [selectedAmenityDetail])

    useEffect(() => {
        if (selectedAmenityDetail) {
            let selectedAmenitiesAttr = Object.keys(selectedAmenityDetail.attr)
            setSelectedAttr(selectedAmenitiesAttr[0])
        }
        setNotUploadedImagesList([])
    }, [selectedAmenityDetail && selectedAmenityDetail["name"]])

    useEffect(() => {
        if (removeImage !== null) {
            setSelectedAmenityDetail(selectedAmenityDetail)
            setNotSupportedImageErr("")
            setNotUploadedImagesList([])
        }
    }, [removeImage])

    useEffect(() => {
        setShowNewAttrTextfield(false)
        setAttributeError(false)
        // setSameAttributeNameError(false)
        setAttributeErrorMsg("")
        setAminityAttrError("")
    }, [selectedAmenityDetail])

    useEffect(() => {
        if (isEdit === true) {
            setAmenityCount(selectedAmenityDetail && selectedAmenityDetail.count)
        }
    }, [selectedAmenityDetail])

    useEffect(() => {
        if (inAddInventory) {

        } else {
            onAmenitiesChange(JSON.stringify(amenities), initialAmenities)
        }
    }, [amenities])

    const setActiveAmenity = (index) => {
        setSelectedAmenity(index)
        setSelectedAttr(selectedAmenityDetail && Object.keys(selectedAmenityDetail.attr)[0])
        setSelectedAmenityDetailIdx(0)
        setSelectedAmenityDetail(amenities[index].data[0])
        fillAmenityDetailList(amenities[index].data, null)
        setTempData({ ...tempData, selectedItem: "" })
        // empty attribute level error object
        setCurrentErrorObj([])

        setTempData({
            ...tempData,
            selectedItem: amenities[index].data[0].name
        })
        onAmenityDetailChange(
            { label: amenities[index].data[0].name, value: amenities[index].data[0].name },
            index)
        setAmenityCount(amenities[index].data.length)
    }

    const fillAmenityDetailList = (data, currentAmenity) => {
        const arr = data.map((el) => {
            return {
                label: el.name,
                value: el.name
            }
        })
        setAmenityDetailList(arr)
        if (currentAmenity !== null) {
            onAmenityDetailChange(
                { label: amenities[currentAmenity].data[0].name, value: amenities[currentAmenity].data[0].name },
                currentAmenity)
        }
    }

    const onAmenityDetailChange = (data, currentAmenity) => {
        const selecAmenity = Number.isInteger(currentAmenity) ? currentAmenity : selectedAmenity
        const index = amenities[selecAmenity].data.findIndex(el => el.name === data.value)
        setSelectedAmenityDetailIdx(index)
        const obj = { ...amenities[selecAmenity].data[index] }
        setSelectedAmenityDetail(obj)
        setSelectedAttr(obj && Object.keys(obj.attr)[0])
        setTempData({ ...tempData, selectedItem: data.value })
        validateCurrentAttr(data.value)
    }

    const validateCurrentAttr = (selectedQuantity) => {
        let found = false
        if (Object.keys(validatorObj).length > 0) {
            Object.keys(validatorObj).forEach(e => {
                Object.keys(validatorObj[e]).forEach(l => {
                    if (l === selectedQuantity) {
                        found = true
                        setCurrentErrorObj(validatorObj[e][l])
                    }
                })
            })
        }
        if (found === false) {
            setCurrentErrorObj([])
        }
    }

    const onQuantityChange = (event) => {
        if (event && event.target.value && isNaN(event.target.value) === false) {
            if (amenities[selectedAmenity]["quantity"] < Number(event.target.value)) {
                addNewItem(Number(event.target.value))
            }
        }
    }

    const updateNumber = (newNum, formKey) => {
        if (newNum < amenities[selectedAmenity].data.length) {
            removeItem()
        } else {
            addNewItem(newNum)
        }
    }

    const removeItem = () => {
        setQuantityList(amenityDetailList)
        setOverlay(true)
        setShowDeleteModal(true)
    }
    const addNewItem = (number) => {
        let arr = objectDeepClone(amenities)
        let initNum = arr[selectedAmenity]["quantity"] + 1
        for (let i = initNum; i <= number; i++) {
            let attr = configData.filter(elem => elem.name.toLowerCase().trim() === arr[selectedAmenity].name.toLowerCase().trim())
            const json = objectDeepClone(defaultObj)
            if (arr[selectedAmenity]._custom === true) {
                json.attr[arr[selectedAmenity].name] = objectDeepClone(defaultAttrs)
            }
            let foundObj = attr && attr.length > 0 ? attr[0].data[0] : json
            foundObj.count = selectedAmenityDetail && selectedAmenityDetail.count || backupCount
            let obj = objectDeepClone(foundObj)
            const newCounter = amenityCount + 1
            setAmenityCount(newCounter)
            setSelectedAmenityDetail({ ...selectedAmenityDetail, count: amenityCount })

            if (isEdit === true) {
                let lastNumbers = [];
                amenityDetailList.map(list => {
                    let lastChar = list.label.slice(list.label.length - 1);
                    lastNumbers.push(parseInt(lastChar))

                })
                let maxNumber = Math.max.apply(Math, lastNumbers) + 1
                obj.name = arr[selectedAmenity].name + " " + maxNumber;
            }
            else {
                let name = duplicateInventoryName(arr[selectedAmenity].data, arr[selectedAmenity].name)
                console.log(name)
                obj.name = name
                // obj.name = arr[selectedAmenity].name +  " " + newCounter
            }
            arr[selectedAmenity].data.push(obj)
        }
        fillAmenityDetailList(arr[selectedAmenity].data, null)
        arr[selectedAmenity]["quantity"] = number
        setAmenities(arr)
    }

    const onAttrChange = (data) => {
        setSelectedAttr(data)
    }

    const onAttrDataChange = (event) => {
        const obj = objectDeepClone(selectedAmenityDetail)
        obj.attr[selectedAttr][event.target.id] = event.target.value
        setSelectedAmenityDetail(obj)
    }

    const onAttributeDataChange = (data) => {
        const obj = objectDeepClone(selectedAmenityDetail)
        obj.attr[selectedAttr]["units"] = data.value
        setSelectedAmenityDetail(obj)
    }

    const onCustomAttrChange = (event) => {
        setCustomAttr({ attributeName: event.target.value })
        setAttributeError(false)
    }

    const submitNewAttr = () => {
        Object.keys(customAttr).map(k => customAttr[k] = typeof customAttr[k] == 'string' ? customAttr[k].trim().replace(/  +/g, ' ') : customAttr[k])
        // const result = formValidator.current.allValid()
        // if (result === false) {
        //     formValidator.current.showMessages()
        //     forceUpdate(true)
        //     return
        // } else {
        //     formValidator.current.hideMessages()
        //     forceUpdate(!update)
        // }


        if (customAttr.attributeName.trim() !== "") {
            const regex2 = /[a-zA-Z]/;
            if (!regex2.test(customAttr.attributeName)) {
                setAttributeError(true)
                setAttributeErrorMsg("Please enter valid name of attribute.")
                return
            }
            else {
                setAttributeError(false)
                setAttributeErrorMsg("")
            }

            let obj = objectDeepClone(selectedAmenityDetail)

            let propertyNames = Object.getOwnPropertyNames(obj.attr);
            let myObject = {};
            let found = false;
            propertyNames.map(function (key, index) {
                if (customAttr.attributeName.toUpperCase() === key.toUpperCase()) {
                    found = true
                }
            });
            if (!found || customAttr.attributeName.toUpperCase() === selectedAttrForEdit.toUpperCase()) {
                // setSameAttributeNameError(false)
                // setAttributeErrorMsg("")
                if (selectedAttrForEdit) {
                    obj.attr[customAttr.attributeName] = objectDeepClone(obj.attr[selectedAttrForEdit])
                    if (customAttr.attributeName.toUpperCase() !== selectedAttrForEdit.toUpperCase()) {
                        delete obj.attr[selectedAttrForEdit]
                    }
                    setSelectedAmenityDetail(obj)
                    setSelectedAttrForEdit("")
                    setSelectedAttr(customAttr.attributeName)
                } else {
                    obj.attr[customAttr.attributeName] = { ...objectDeepClone(defaultAttrs), _isCustom: true }
                    setSelectedAmenityDetail(obj)
                }
                setShowNewAttrTextfield(false)
            } else {
                // setSameAttributeNameError(true)
                setAttributeError(true)
                setAttributeErrorMsg("Attribute name can't be same.")
            }

        } else {
            // setSameAttributeNameError(false)
            setAttributeError(true)
            setAttributeErrorMsg("Name of attribute field is required.")
        }
    }

    const addNewAttr = () => {
        setCustomAttr({ attributeName: "" })
        setAddCTA("+ Add")
        setShowNewAttrTextfield(true)
    }

    const removeSelectedAmenity = (index, name) => {
        let amenityIndex;
        amenityList.map((am, i) => {
            if (name.startsWith(am.name)) {
                amenityIndex = i
            }
        })

        let filterSelectedAmenityDetails = amenities[amenityIndex].data.filter(function (el) {
            return el.name == name;
        })
        setSelectedDeleteAmenityName(name)
        if (filterSelectedAmenityDetails) {
            setSelectedDeleteAmenityDetails(filterSelectedAmenityDetails);
        }
        setToggleAmenityDeleteConfirm(true)
        setOverlay(true)
        setSelectedDeleteAmenityIndex(index)
    }

    const deleteSingleUploadedImage = (imageKey) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ key: imageKey && imageKey })
        }

        fetch('/api/deleteUploadedFiles', requestOptions)
            .then((res) => {
                res.json()
            })
            .catch(err => console.log(err))
    }

    const deleteImage = async (imageKey) => {
        console.log("imageKey>>", imageKey);
        if (isEdit === true && selectedAmenityDetail._id === undefined) {
            deleteSingleUploadedImage(imageKey);
        } else if (isEdit === true) {
            let idMatch = false;
            if (selectedAmenityDetail._id) {
                let findId = await context.apis[context.user.role].findInventory(context.validator, { _id: selectedAmenityDetail._id }, {})
                if (findId && findId.response && Object.keys(findId.response).length > 0) {
                    idMatch = true
                } else {
                    idMatch = false
                }
            }
            if (idMatch === true) {
                parentData.data.removeSingleUploadedImageArray.push(imageKey)
            } else {
                deleteSingleUploadedImage(imageKey);
            }
        } else {
            deleteSingleUploadedImage(imageKey);
        }
        const key = imageKey;
        let updateImageObj = selectedAmenityDetail.images.filter(e => e.key !== key);
        console.log("updateImageObj>>>>>", updateImageObj);
        setImagesArray(objectDeepClone(updateImageObj))
        setSelectedAmenityDetail({
            ...selectedAmenityDetail,
            images: objectDeepClone(updateImageObj)
        })
        if (removeImage !== null) {
            setRemoveImage(!removeImage)
        } else {
            setRemoveImage(false)
        }
    }

    console.log("selectedAmenityDetail--->>>", selectedAmenityDetail);

    const deleteMultipleUploadedImages = (amenitydata) => {
        if (amenitydata !== [] && selectedDeleteAmenityDetails !== []) {
            let imagesKeyArray = [];
            amenitydata && amenitydata[0].images.map(img => {
                imagesKeyArray.push({ Key: img.key })
            })

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ keys: imagesKeyArray && imagesKeyArray })
            }

            fetch('/api/deleteMultipleUploadedFiles', requestOptions)
                .catch(err => console.log(err))
        }

    }

    const amenityConfirmClose = async (confirm) => {
        if (confirm === true) {
            setAminityDeleteInProgress(true)
            if (isEdit === true) {
                parentData.data.removeInventoriesArray.push(selectedDeleteAmenityDetails[0]._id)
            }
            setBackupCount(amenityCount)
            const arr = objectDeepClone(amenityDetailList)
            arr.splice(selectedDeleteAmenityIndex, 1)
            setAmenityDetailList(arr)
            setQuantityList(arr)
            let amenityList = objectDeepClone(amenities);
            if (selectedDeleteAmenityDetails !== []) {
                if (isEdit === true) {
                    let idMatch = false;
                    if (selectedAmenityDetail._id) {
                        let findId = await context.apis[context.user.role].findInventory(context.validator, { _id: selectedAmenityDetail._id }, {})
                        if (findId && findId.response && Object.keys(findId.response).length > 0) {
                            idMatch = true
                        } else {
                            idMatch = false
                        }
                    }
                    if (idMatch === true) {
                        parentData.data.removeUploadedImageArray.push(selectedDeleteAmenityDetails)
                    } else {
                        await deleteMultipleUploadedImages(selectedDeleteAmenityDetails);
                    }
                } else {
                    await deleteMultipleUploadedImages(selectedDeleteAmenityDetails);
                }
            }

            if (!hideRevenue) {

                let selectedInventoriesForAlgo = parentData.data.algorithmData.inventories;
                let filterArr = selectedInventoriesForAlgo.filter(element => selectedDeleteAmenityDetails[0].name !== element.uniqueName)

                console.log("selectedDeleteAmenityDetails>", selectedDeleteAmenityDetails);
                console.log("selectedInventoriesForAlgo>", selectedInventoriesForAlgo, filterArr);

                if (parentData && parentData.setData) {
                    parentData.setData({
                        ...parentData.data,
                        algorithmData: {
                            ...parentData.data.algorithmData,
                            inventories: filterArr
                        }
                    })
                }

                let resp = await context.apis.public.getOnboardingRevenue(
                    context.validator, {
                    ...parentData.data.algorithmData,
                    inventories: filterArr
                }
                )
                if (resp && resp.done) {
                    if (parentData && parentData.setNetRevenue) {
                        parentData.setNetRevenue(resp.netRevenue || 0)
                    }
                }

                console.log("algo resp->>>>", resp);
            }

            amenityList[selectedAmenity].quantity = arr.length
            amenityList[selectedAmenity].data.splice(selectedDeleteAmenityIndex, 1)

            setAmenities(amenityList)
        }
        setToggleAmenityDeleteConfirm(false)
        setAminityDeleteInProgress(false)
    }

    const closeDeleteModal = () => {
        const data = amenityDetailList[0];
        // setQuantityErrorMessage("")
        validateSelectedAmenity()
        forceUpdate(!update)
        onAmenityDetailChange(data, null)
        setShowDeleteModal(false)
        setOverlay(false)
    }

    const removeCustomAddedAttr = (el) => {
        setSelectedCustomAttrName(el)
        setToggleCustomAttrDeleteConfirm(true)
        setOverlay(true)
    }

    const attrConfirmClose = (confirm) => {
        if (confirm === true) {
            let obj = objectDeepClone(selectedAmenityDetail)
            if (isEdit === true) {
                parentData.data.removeInventoriesAttributeArray.push(obj.attr[selectedCustomAttrName]._id)
            }
            delete obj.attr[selectedCustomAttrName]
            setSelectedAmenityDetail(obj)
            setSelectedAttr(obj && Object.keys(obj.attr)[0])
        }
        setToggleCustomAttrDeleteConfirm(false)
        setOverlay(false)
    }

    const editCustomAttr = (el) => {
        setAddCTA("Save")
        setSelectedAttrForEdit(el)
        setShowNewAttrTextfield(true)
        setCustomAttr({ attributeName: el.trim() })
    }

    const openImagePreview = (index) => {
        setSelectedImageIndex(index)
        setShowImagePreview(true)
    }

    const onAttrSubmit = async () => {

        if (selectedAmenityDetail && selectedAmenityDetail.customName && Object.keys(selectedAmenityDetail.customName)) {
            setSelectedAmenityDetail({ ...selectedAmenityDetail, customName: selectedAmenityDetail.customName.trim().replace(/  +/g, ' ') })
        }
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }
        setNotSupportedImageErr("")
        setNotUploadedImagesList([])
        let validator = {}
        validator = await validateData().catch(err => console.log(err))
        const amenitiesArr = objectDeepClone(amenities)
        const amenityDetailListArr = objectDeepClone(amenityDetailList)
        let validated = true
        Object.keys(validator).forEach(el => {
            Object.keys(validator[el]).forEach(subEl => {
                if (validated === true && selectedAmenityDetail.name === subEl) {
                    validated = false
                }
            })
        })
        if (Object.keys(validator).length < 1) {
            updateParentErrorMsg("", false)
        }
        if (validated) {
            if (selectedAmenityDetailIdx < (amenityDetailListArr.length - 1)) {
                const data = amenityDetailListArr[selectedAmenityDetailIdx + 1]
                onAmenityDetailChange(data, null)
            } else {
                if (selectedAmenity < (amenitiesArr.length - 1)) {
                    setActiveAmenity(selectedAmenity + 1)
                }
                const completedAmenityName = amenitiesArr[selectedAmenity].name
                setCompletedAmenities([
                    ...completedAmenities,
                    completedAmenityName
                ])
            }
            if (!hideRevenue) {
                let algoData = {
                    placement: [],
                    cpi: [],
                    materialCost: [],
                    size: [],
                    unit: [],
                    quantity: [],
                    changes: [],
                    uniqueName: ""
                }
                let arr = Object.keys(amenitiesArr[selectedAmenity].data[selectedAmenityDetailIdx].attr);

                // if (!amenitiesArr[selectedAmenity]._custom) {
                    algoData["parentInventory"] = amenitiesArr[selectedAmenity].data[selectedAmenityDetailIdx].name.replace(/[0-9]/g, '').trim();
                    algoData["uniqueName"] = amenitiesArr[selectedAmenity].data[selectedAmenityDetailIdx].name
                    for (let propertyName of arr) {
                        let attribute = amenitiesArr[selectedAmenity].data[selectedAmenityDetailIdx].attr[propertyName]
                        let length = Number(attribute.length) === 0
                        let breadth = Number(attribute.breadth) === 0
                        let height = Number(attribute.height) === 0
                        let quantity = Number(attribute.quantity) === 0

                        console.log("length && breadth && height && quantity", length && breadth && height && quantity);
                        if (length && breadth && height && quantity) {

                        } else {
                            // if (!attribute._isCustom) {
                                let cat = parentData.data.algorithmData.category || "C"
                                let cpiCategory = !amenitiesArr[selectedAmenity]._custom && !attribute._isCustom ? attribute.cpi.filter(element => element.category === cat) : [];
                                !amenitiesArr[selectedAmenity]._custom && !attribute._isCustom && algoData['cpi'].push(
                                    cpiCategory[0].value || 0
                                )
                                // if(attribute.materialCost){
                                //     algoData['materialCost'].push(1)
                                // }else{
                                    !amenitiesArr[selectedAmenity]._custom && !attribute._isCustom && algoData['materialCost'].push(attribute.materialCost)
                                // }
                                let length = Number(attribute.length) === 0 ? 1 : Number(attribute.length)
                                let breadth = Number(attribute.breadth) === 0 ? 1 : Number(attribute.breadth)
                                let height = Number(attribute.height) === 0 ? 1 : Number(attribute.height)
                                let size = length * breadth
                                algoData.size.push(size)
                                algoData.unit.push(attribute.units)
                                algoData.quantity.push(Number(attribute.quantity))
                                !amenitiesArr[selectedAmenity]._custom && !attribute._isCustom && algoData.changes.push(Number(attribute.noOfChangesYearly))
                                algoData.placement.push(propertyName)

                            // }
                            // else{
                            //     algoData['cpi'].push(0)
                            //     algoData['materialCost'].push(1)
                            //     let length = Number(attribute.length) === 0 ? 1 : Number(attribute.length)
                            //     let breadth = Number(attribute.breadth) === 0 ? 1 : Number(attribute.breadth)
                            //     let height = Number(attribute.height) === 0 ? 1 : Number(attribute.height)
                            //     let size = height * length * breadth
                            //     algoData.size.push(size)
                            //     algoData.unit.push(attribute.units)
                            //     algoData.quantity.push(Number(attribute.quantity))
                            //     algoData.changes.push(1)
                            //     algoData.placement.push(propertyName)
                            //     // algoData["parentInventory"] = amenitiesArr[selectedAmenity].data[selectedAmenityDetailIdx].name.replace(/[0-9]/g, '').trim();
                            //     // algoData["uniqueName"] = amenitiesArr[selectedAmenity].data[selectedAmenityDetailIdx].name
                            // }
                        }

                    }
                    let allSelectedInventoryArr = [];
                    let allAmenities = [...parentData.data.schoolAmenities.classroomAmenities, ...parentData.data.schoolAmenities.commonAreaAmenities, ...parentData.data.schoolAmenities.otherRoomsAndStaffAmenities, ...parentData.data.schoolAmenities.outdoorAmenities]
                    allSelectedInventoryArr = parentData.data.algorithmData.inventories.filter(el => {
                        return allAmenities.find(element => {
                            return element.data.find(data => {
                                return element.name === el.parentInventory && data.name === el.uniqueName;
                            })
                        });
                    });
                    console.log("allSelectedInventoryArr", allSelectedInventoryArr);

                    let allAlgoInventories = [algoData, ...allSelectedInventoryArr]

                    console.log("allAlgoInventories", allAlgoInventories);

                    let data = allAlgoInventories.filter((am, index, self) =>
                        index === self.findIndex((t) => (
                            t.parentInventory === am.parentInventory && t.uniqueName === am.uniqueName
                        ))
                    )
                    console.log("algoData>>>--", algoData, data);

                    if (parentData && parentData.setData) {
                        parentData.setData({
                            ...parentData.data,
                            algorithmData: {
                                ...parentData.data.algorithmData,
                                inventories: data
                            }
                        })
                    }

                    let resp = await context.apis.public.getOnboardingRevenue(
                        context.validator, {
                        ...parentData.data.algorithmData,
                        inventories: data
                    }
                    )
                    if (resp && resp.done) {
                        if (parentData && parentData.setNetRevenue) {
                            parentData.setNetRevenue(resp.netRevenue || 0)
                        }
                    }
                    console.log("algo resp->>>>", resp);
                // }
                // else {
                //     for(let propertyName of arr){
                //         let attribute = amenitiesArr[selectedAmenity].data[selectedAmenityDetailIdx].attr[propertyName]
                //         algoData['cpi'].push(0)
                //         algoData['materialCost'].push(1)
                //         let length = Number(attribute.length) === 0 ? 1 : Number(attribute.length)
                //         let breadth = Number(attribute.breadth) === 0 ? 1 : Number(attribute.breadth)
                //         let height = Number(attribute.height) === 0 ? 1 : Number(attribute.height)
                //         let size = height * length * breadth
                //         algoData.size.push(size)
                //         algoData.unit.push(attribute.units)
                //         algoData.quantity.push(Number(attribute.quantity))
                //         algoData.changes.push(1)
                //         algoData["parentInventory"] = amenitiesArr[selectedAmenity].data[selectedAmenityDetailIdx].name.replace(/[0-9]/g, '').trim();
                //         algoData["uniqueName"] = amenitiesArr[selectedAmenity].data[selectedAmenityDetailIdx].name
                //     }
                // }


            }
        }
    }

    const mainAmenityConfirmClose = (confirm) => {
        if (confirm === true) {
            setSelectedAttr(selectedAmenityDetail && Object.keys(selectedAmenityDetail.attr)[0])
            setSelectedAmenity(null)
            onCustomAmenityDelete(selectedMainAmenityToDeleteIdx)
        }
        setToggleMainAmenityDeleteConfirm(false)
        setOverlay(false)
    }

    const validateSelectedAmenity = () => {
        if (selectedAmenity !== null && Object.keys(validatorObj).length > 0) {
            let pendingQuantities = []
            Object.keys(validatorObj).forEach(e => {
                if (amenities[selectedAmenity].name === e) {
                    pendingQuantities = Object.keys(validatorObj[e])
                }
            })
            if (pendingQuantities.length > 0) {
                pendingQuantities = [...pendingQuantities.filter(item => amenities[selectedAmenity].data?.find(item1 => item1.name === item))]
                const errorStr = "Please fill all fields for " + pendingQuantities.join(", ") + "."
                if (errorStr === "Please fill all fields for ") {
                    setQuantityErrorMessage("")
                }
                else {
                    setQuantityErrorMessage(errorStr)
                }
                const data = amenityDetailList[selectedAmenityDetailIdx]
                if (data && data.value) {
                    validateCurrentAttr(data.value)
                }
            } else {
                setQuantityErrorMessage("")
                setCurrentErrorObj([])
            }
        }
        if (Object.keys(validatorObj).length === 0) {
            setQuantityErrorMessage("")
            setCurrentErrorObj([])
        }
    }

    const validateData = () => {
        return new Promise(resolve => {
            let validator = {}
            let completedList = objectDeepClone(completedAmenities)
            amenities.forEach((el, i) => {
                let isEmpty = false
                let quantityNotZero = []
                let lbhNotZero = []
                let attrList = []
                el.data.forEach((d, index) => {
                    Object.keys(d.attr).forEach(att => {
                        let emptyCount = 0;
                        Object.keys(amenities[i].data[index].attr).forEach(a => {
                            Object.keys(amenities[i].data[index].attr[a]).forEach(attr => {
                                if (
                                    (!amenities[i].data[index].attr[a][attr])) //  || amenities[i].data[index].attr[a][attr] === "0"
                                {
                                    if (inDashboard) {
                                        if (attr === "quantity" || attr === "length" || attr === "height" || attr === "breadth") {
                                            if ((amenities[i].data[index].attr[a][attr] === "")) {
                                                isEmpty = true
                                                if (!attrList.find(l => l === a)) attrList = [...attrList, a]
                                            }
                                        }

                                    } else {
                                        if ((amenities[i].data[index].attr[a][attr] === "")) {
                                            isEmpty = true
                                            if (!attrList.find(l => l === a)) attrList = [...attrList, a]
                                        }
                                    }

                                }
                            })
                            console.log("isEmpty------->>>", isEmpty,)
                            if (amenities[i]) {
                                if ((Number(amenities[i].data[index].attr[a]["quantity"]) > 0) && (Number(amenities[i].data[index].attr[a]["length"]) === 0) && (Number(amenities[i].data[index].attr[a]["breadth"]) === 0) && (Number(amenities[i].data[index].attr[a]["height"]) === 0)) {
                                    isEmpty = true
                                    console.log("condition satisfied", isEmpty);
                                    if (!attrList.find(l => l === a)) attrList = [...attrList, a]
                                }

                                if ((Number(amenities[i].data[index].attr[a]["quantity"]) === 0) && ((Number(amenities[i].data[index].attr[a]["length"]) > 0) || (Number(amenities[i].data[index].attr[a]["breadth"]) > 0) || (Number(amenities[i].data[index].attr[a]["height"]) > 0))) {
                                    isEmpty = true
                                    console.log("condition satisfied", isEmpty);
                                    if (!attrList.find(l => l === a)) attrList = [...attrList, a]
                                }
                                if (Number(amenities[i].data[index].attr[a]["quantity"]) === 0 && Number(amenities[i].data[index].attr[a]["length"]) === 0 && Number(amenities[i].data[index].attr[a]["breadth"]) === 0 && Number(amenities[i].data[index].attr[a]["height"]) === 0) {
                                    emptyCount++;
                                }
                            }
                            // if((amenities[i].data[index].attr[a]["quantity"] === "0" ||  amenities[i].data[index].attr[a]["quantity"] === 0) && ((amenities[i].data[index].attr[a]["length"] !== "0" ||  amenities[i].data[index].attr[a]["length"] > 0) || (amenities[i].data[index].attr[a]["height"] !== "0" ||  amenities[i].data[index].attr[a]["height"] > 0) || (amenities[i].data[index].attr[a]["breadth"] !== "0" ||  amenities[i].data[index].attr[a]["breadth"] > 0))){
                            //     isEmpty = true
                            // }
                            // else{
                            //     // lbhNotZero.push(false)
                            //     isEmpty = false
                            // }
                            // if((amenities[i].data[index].attr[a]["quantity"] !== "0" ||  amenities[i].data[index].attr[a]["quantity"] > 0) && ((amenities[i].data[index].attr[a]["length"] === "0" ||  amenities[i].data[index].attr[a]["length"] === 0) && (amenities[i].data[index].attr[a]["height"] === "0" ||  amenities[i].data[index].attr[a]["height"] === 0) && (amenities[i].data[index].attr[a]["breadth"] === "0" ||  amenities[i].data[index].attr[a]["breadth"] === 0))){
                            //     // quantityNotZero.push(true)
                            //     isEmpty = true
                            // }
                            // else{
                            //     quantityNotZero.push(false)
                            //     isEmpty = false
                            // }
                            console.log("a->>>", { ...attrList });
                        })
                        console.log("Object.keys(amenities[i].data[index].attr).length", Object.keys(amenities[i].data[index].attr).length)
                        if (emptyCount === Object.keys(amenities[i].data[index].attr).length) {
                            isEmpty = true
                        }
                    })
                    console.log("isEmpty----2", isEmpty, el, attrList)
                    if (isEmpty === true) {
                        const foundCompletedIndex = completedList.findIndex(e => e === el.name)
                        console.log("completedList--->>>", completedList);
                        console.log("foundCompletedIndex---->>>", foundCompletedIndex);
                        if (foundCompletedIndex >= 0) {
                            completedList.splice(foundCompletedIndex, 1)
                        }
                        setCompletedAmenities(completedList)
                        validator = {
                            ...validator,
                            [el.name]: {
                                ...validator[el.name],
                                [d.name]: attrList
                            }
                        }
                        console.log("validator---->>>", validator);
                    }
                    isEmpty = false
                    // quantityNotZero = []
                    // lbhNotZero = []
                })
            })

            if (selectedAmenityDetail && selectedAmenityDetail.customName && Object.keys(selectedAmenityDetail.customName)) {
                // setSelectedAmenityDetail({...selectedAmenityDetail, customName: selectedAmenityDetail.customName.trim().replace(/  +/g, ' ')})
                console.log()
                const result = formValidator.current.fieldValid("customName")
                if (result === false) {
                    formValidator.current.showMessageFor("customName")
                    forceUpdate(true)
                    validator = {
                        ...validator,
                        customName: "customName"
                    }
                    return
                } else {
                    formValidator.current.hideMessageFor("customName")
                    forceUpdate(!update)
                }
            }

            setValidatorObj(validator)
            resolve(validator)
        })
    }

    const handleUpload = async (event, files) => {
        setToggleImageLoadingPopUp(true)
        let timeoutId
        setNotUploadedImagesList([])
        // const dt = new DataTransfer()
        // event.target.files = dt.files
        setImagesCount([])
        let imagesList = []
        let imagesUploadedList = []
        let updateImageObj = objectDeepClone(imagesArray)
        for (let file of files) {
            imagesList.push(file.name)
            if (!file.type.startsWith("image/")) {
                setToggleImageLoadingPopUp(false)
                setNotSupportedImageErr(file.name)
                event.target.value = null;
                return
            } else {
                setNotSupportedImageErr("")
            }
        }
        const controller = new AbortController()
        timeoutId = setTimeout(() => {
            controller.abort();
            let notUploadedImages = imagesList.filter(function (obj) { return imagesUploadedList.indexOf(obj) == -1; });
            setIsLoading(false)
            setNotUploadedImagesList(notUploadedImages)
        }, 60000)

        for (let i = 0; i < files.length; i++) {
            setIsLoading(true);
            const formData = new FormData();
            setImagesCount(prev => [
                ...prev,
                files[i]
            ])
            const resizedImage = await compress.compress([files[i]], {
                size: 2, // the max size in MB, defaults to 2MB
                quality: 0.4, // the quality of the image, max is 1,
                resize: false // defaults to true, set false if you do not want to resize the image width and height
            })
            const img = resizedImage[0];
            const base64str = img.data
            const imgExt = img.ext
            let compressedFile = Compress.convertBase64ToFile(base64str, imgExt);
            formData.append(`image`, compressedFile, img.alt);
            let reqBody = formData
            if (inDashboard) {
                let schoolName = await context.apis[context.user.role].getSchoolName(context.validator, {})
                console.log("schoolName--->>>", schoolName);
                if (schoolName.done) {
                    let destinationFolder = `schools/${context?.user?.school}-${schoolName.response.schoolName}/inventories`
                    reqBody.append('destinationFolder', destinationFolder)
                }

            }
            console.log("formData--->>>>", formData, reqBody, inDashboard)
            const requestOptions = {
                signal: controller.signal,
                method: 'POST',
                body: reqBody
            };
            let publicContextInitiator = new PublicContextInitiator(context)

            try {
                const response: any = await fetch('/api/uploadFiles', requestOptions).catch(err => console.log(err))
                console.log("response", response)
                const res = await response.json()
                let data = [];
                imagesUploadedList.push(img.alt)

                for (let item of res["response"]) {
                    data.push(item.Key)
                }

                const myRes = await publicContextInitiator.getUploadedImagesSignedUrl(context.validator, { keys: data })
                for (let item of myRes.response) {
                    updateImageObj.push({ image: item.image, key: item.key });
                    parentData.data.unsavedImages.push(item.key)
                }
                if (files.length - 1 === i) {
                    // setIsImagesUploaded(!isImagesUploaded)
                }
            } catch (error) {
                console.log("err", error);
            }
        }
        setImagesArray(objectDeepClone(updateImageObj))
        setSelectedAmenityDetail({
            ...selectedAmenityDetail,
            images: objectDeepClone(updateImageObj)
        })
        clearTimeout(timeoutId)
        setIsLoading(false)
        setToggleImageLoadingPopUp(false)
        event.target.value = null;
    }
    useEffect(() => {
        if (!parentData?.tooltipProgress?.inventoryDetail1) {
            if (selectedAmenity !== undefined && selectedAmenity !== null) {
                setOnboarding2({ ...onBoarding2, stepsEnabled: parentData?.tooltipProgress?.inventoryDetail2 })
            }
        }
    }, [selectedAmenity, parentData?.tooltipProgress?.inventoryDetail1])
    const hanldeOnboardingComplete2 = () => {
        parentData.setTooltipProgress({
            ...parentData.tooltipProgress,
            inventoryDetail2: false
        })
        let progress = {
            ...parentData.tooltipProgress,
            inventoryDetail2: false
        }
        localStorage.setItem("tooltipProgress", JSON.stringify(progress))
    }
    const exitOnBoarding2 = (e) => {
        console.log(e)
    }

    const handleBeforeChange = (index) => {
        if (index !== null && index !== undefined) {
            let data = onBoarding2.steps[index].element.split("")
            let array = data.slice(1).join("")
            // console.log(array)
            if (data[0] === "#") {
                document.getElementById(array).scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" })
            }
            else {
                document.getElementsByClassName(array)[0].scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" })
            }
        }
        // console.log(index)
    }

    // formValidator.current.rules['custom_Attr'] = {
    //     message: "Please enter valid Name of Attribute",
    //     rule: (val) => {
    //         let returnValue = true
    //         const regex2 = /[a-zA-Z]/;
    //         if (!regex2.test(val)) {
    //             returnValue = false
    //         }

    //         return returnValue
    //     },
    // }
    formValidator.current.rules['inventory_name'] = {
        message: "Please enter valid name of Inventory.",
        rule: (val) => {
            let returnValue = true
            const regex2 = /[a-zA-Z]/;
            if (!regex2.test(val)) {
                returnValue = false
            }
            return returnValue
        },
    }
    return (
        <>
            <div className={`ovelap ${(overlay === true || toggleImageLoadingPopUp === true) ? "active" : ""}`}></div>
            <div className="school-specify relative">
                {/* below ovelaper class added for showing upload in progress until images uploading completes. */}
                <article className={`tabbed-content tabs-side ${toggleImageLoadingPopUp && "ovelaper active"}`}>
                    <nav className="tabs abc">
                        <ul>
                            {
                                (amenities && amenities.length > 0) &&
                                amenities.map((el, index) => {
                                    const isCompleted = completedAmenities.find(c => c === el.name)
                                    const isPending = Object.keys(validatorObj).find(v => v === el.name)
                                    return (
                                        <li key={index}>
                                            <a
                                                id={`amenityDetail${index}`}
                                                onClick={() => setActiveAmenity(index)}
                                                href="javascript:void(0)"
                                                className={
                                                    `star-icon ${index === selectedAmenity ? "active" : ""}
                                                        ${isCompleted ? "complete" : ""}
                                                        ${isPending ? "pending-amenity" : ""}
                                                    `}
                                            >
                                                {el._custom === true &&
                                                    <img className="checkcom star" src={star} />
                                                }
                                                <h4>{el.name}</h4>
                                                <p>Total - {el.quantity}</p>
                                                <img src={check} />
                                            </a>
                                            {/* {
                                                    el._custom === true &&
                                                    <a
                                                        className="delete-custom-inventory"
                                                        href="javascript:void(0)">
                                                    <img src={closeBG} /></a>
                                                } */}
                                        </li>
                                    )
                                })
                            }
                            <li>
                                <div className='facili-avai1 add' onClick={() => openRemainingInventories()}>
                                    {" "}
                                    <img src={addBox} />{" "}
                                </div>
                            </li>
                        </ul>
                    </nav>
                    {
                        selectedAmenity === null ?
                            <div id="side_img" className="item active" data-title="">
                                <div className="item-content">
                                    <div className="tabblank"><img src={tabBlank} /><p>Select an option to provide <br /> more details.</p></div>
                                </div>
                            </div>
                            :
                            <div id="side_tab1" className="item active">
                                <div className="item-content">
                                    <div className="scho-spec">
                                        <div className="row">
                                            <div id="addMore" style={{ display: "flex", justifyContent: "space-between" }} className="row col-xl-12 col-lg-12 col-md-12 col-xs-12 mt20">
                                                <div className="row col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 middle-xs">
                                                    <p className="ptx2">Number of {amenities[selectedAmenity]?.name}</p>
                                                </div>
                                                <div className="row col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 end-md">
                                                    {
                                                        amenities?.[selectedAmenity] &&
                                                        <NumWithPlusMinus
                                                            maxLimit={10}
                                                            minLimit={1}
                                                            formData={amenities[selectedAmenity]}
                                                            onDataChange={onQuantityChange}
                                                            updateNumber={updateNumber}
                                                            formKey="quantity"
                                                            isDisabled={true}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20"></div>
                                            <SingleSelect
                                                formData={tempData}
                                                formKey="selectedItem"
                                                onDataChange={onAmenityDetailChange}
                                                placeholder="Select Amenity"
                                                isRequired={false}
                                                options={amenityDetailList}
                                                wrapperClasses="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 middle-xs"
                                            />
                                            {
                                                quantityErrorMessage && quantityErrorMessage !== "" &&
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"><p className="valError">
                                                    <img src={errorSVG} />
                                                    {quantityErrorMessage}
                                                </p></div>
                                            }
                                            {
                                                tempData.selectedItem &&
                                                <>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20"></div>
                                                    <Textfield
                                                        formData={selectedAmenityDetail}
                                                        formKey="customName"
                                                        onDataChange={(event) => setSelectedAmenityDetail({ ...selectedAmenityDetail, customName: event.target.value })}
                                                        placeholder='Enter Inventory Name'
                                                        isRequired={false}
                                                        wrapperClasses="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 middle-xs"
                                                        formValidator={formValidator}
                                                        validation='inventory_name'
                                                        maxLength={50}
                                                    />
                                                </>
                                            }
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20">
                                                <div id="addImages" className="sch-add-img">
                                                    <h3 className="htx2">Images
                                                        <a href="javascript:void(0);" className="add-inve adbrose">+ Add
                                                            <input name="amenityImages" type="file" accept="image/*" title="" onChange={(event) => handleUpload(event, event.target.files)} multiple={true} />
                                                        </a>
                                                    </h3>
                                                    <img src={imageBase64String} className="img-responsiv" />
                                                    <section id="gallery">
                                                        <div className="container">
                                                            <div id="image-gallery">
                                                                {
                                                                    imagesArray && imagesArray.map((el, index) => {
                                                                        return (
                                                                            <div className="image" key={index} style={{ height: '55px' }}>

                                                                                <div onClick={() => openImagePreview(index)} id="imageId1" className="img-wrapper">
                                                                                    <a href="javascript:void(0)"><img src={el.image} className="img-responsive" /></a>
                                                                                    <div className="img-overlay"> <i className="fa fa-eye" aria-hidden="true"></i> </div>
                                                                                </div>
                                                                                <a href="javascript:void(0)" onClick={() => deleteImage(el.key)} className="removeImg"><i className="fa fa-plus-circle" aria-hidden="true"></i></a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    isLoading ?
                                                                        imagesCount && imagesCount.map(image => {
                                                                            return (
                                                                                <div className="image" title={image.title} style={{ height: '55px' }}>
                                                                                    <div className="loader-imgupload"></div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        ""
                                                                }
                                                            </div>

                                                            {
                                                                notUploadedImagesList && notUploadedImagesList.length > 0 &&
                                                                <p
                                                                    style={{
                                                                        fontSize: "0.8rem",
                                                                        color: "tomato",
                                                                        paddingTop: "0.5rem",
                                                                    }}
                                                                >
                                                                    Failed to upload : {
                                                                        notUploadedImagesList && notUploadedImagesList.length > 0 && notUploadedImagesList.map((image, index) => {
                                                                            return (
                                                                                <span className="valError"><img src={errorSVG} /><span> {image}{notUploadedImagesList.length === index + 1 ? "." : ", "} </span></span>
                                                                            )
                                                                        })
                                                                    }
                                                                </p>
                                                            }
                                                            {
                                                                notSupportedImageErr && notSupportedImageErr.length > 0 &&
                                                                <>
                                                                    <p
                                                                        style={{
                                                                            fontSize: "0.8rem",
                                                                            color: "tomato",
                                                                            paddingTop: "0.5rem",
                                                                        }}
                                                                    >
                                                                        <span className="valError">
                                                                            <img src={errorSVG} />
                                                                            <p>The uploaded file is not supported. Please upload image file.</p>
                                                                        </span>
                                                                    </p>
                                                                    {/* <p
                                                            style={{
                                                            fontSize: "0.8rem",
                                                            color: "tomato",
                                                            paddingTop: "0.5rem",
                                                            }}
                                                            >
                                                                The uploaded file is not supported. Please upload image file.
                                                            </p> */}
                                                                </>
                                                            }
                                                            <ImagePreview
                                                                setShowImagePreview={setShowImagePreview}
                                                                showImagePreview={showImagePreview}
                                                                images={imagesArray && imagesArray}
                                                                selectedImageIndex={selectedImageIndex}
                                                            />
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20">
                                                <div className="sch-add-img ifnotline">
                                                    <div id="addNewAttribute" className="d-flex justify-content-space-between">
                                                        <h3 className="htx2">Size Info:
                                                            {/* <p className="ptx" style={{display: "inline", marginLeft: "3px"}}>(Please enter "0" if not applicable)</p> */}
                                                            <p className="ptx">Add sizes for branding only</p>
                                                        </h3>
                                                        {showNewAttrTextfield !== true &&
                                                            <a onClick={addNewAttr} href="javascript:void(0);" className="add-inve nameadd">+ Add</a>
                                                        }
                                                    </div>
                                                    <div className={`addnameart ${showNewAttrTextfield === true ? "active" : ""}`}>
                                                        {/* <input 
                                                    onChange={onCustomAttrChange} 
                                                    name="" value={customAttr} 
                                                    placeholder="Name of attribute" 
                                                    type="text"/> */}
                                                        <TextField
                                                            onDataChange={onCustomAttrChange}
                                                            formData={customAttr}
                                                            formKey={'attributeName'}
                                                            placeholder="Name of attribute"
                                                            isRequired={false}
                                                            maxLength={50}
                                                        // formValidator={formValidator}
                                                        // validation='custom_Attr'
                                                        />

                                                        <a onClick={submitNewAttr} href="javascript:void(0);" className="add-inve">{addCTA}</a>
                                                    </div>
                                                    {attributeError && (
                                                        <p
                                                            style={{
                                                                fontSize: "0.8rem",
                                                                color: "tomato",
                                                                paddingTop: "0.5rem",
                                                            }}
                                                        ><span className="valError"><img src={errorSVG} />{attributeErrorMsg}</span>
                                                        </p>
                                                    )}
                                                    {/* {
                                                    sameAttributeNameError &&
                                                    <p
                                                        style={{
                                                            fontSize: "0.8rem",
                                                            color: "tomato",
                                                            paddingTop: "0.5rem",
                                                        }}                                                                
                                                    >
                                                    Attribute name can't be same
                                                    </p>
                                                    } */}
                                                    <div className="attri-add">
                                                        <div className="tabset">
                                                            <div className="tabs-scrollhori">
                                                                {
                                                                    (selectedAmenityDetail && selectedAmenityDetail.attr) &&
                                                                    Object.keys(selectedAmenityDetail.attr).map((el, index) => {
                                                                        const showErrorState = currentErrorObj.find((item) => item === el)
                                                                        return (
                                                                            <Fragment key={index}>
                                                                                <input
                                                                                    value={selectedAttr}
                                                                                    key={index}
                                                                                    onChange={() => onAttrChange(el)}
                                                                                    type="radio"
                                                                                    name="attr"
                                                                                    id={el}
                                                                                    aria-controls={el}
                                                                                    checked={selectedAttr === el}
                                                                                />
                                                                                <label htmlFor={el}
                                                                                    onDoubleClick={() => selectedAmenityDetail.attr[el]._isCustom === true && editCustomAttr(el)}
                                                                                    className={`tabremove ${showErrorState ? "attr-error" : ""}`}
                                                                                >
                                                                                    {el}
                                                                                    {
                                                                                        selectedAmenityDetail.attr[el]._isCustom === true &&
                                                                                        <>
                                                                                            <img src={star} alt="star" />
                                                                                            <a onClick={() => removeCustomAddedAttr(el)} href="javascript:void(0)" className="remove-icon"><img src={closeBG} alt="close" />
                                                                                            </a>
                                                                                        </>
                                                                                    }
                                                                                </label>
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="tab-panels">
                                                                {
                                                                    (selectedAmenityDetail && selectedAmenityDetail.attr) &&
                                                                    Object.keys(selectedAmenityDetail.attr).map((el, index) => {
                                                                        return (
                                                                            <div key={index} id={el} className={`tab-panel ${selectedAttr === el ? "active" : ""}`}>
                                                                                {
                                                                                    (selectedAttr && selectedAmenityDetail.attr.hasOwnProperty(selectedAttr)) &&
                                                                                    Object.keys(selectedAmenityDetail.attr[selectedAttr]).filter(f => f.toLowerCase().trim() !== "_iscustom").map((a, aIndex) => {
                                                                                        console.log("Object.keys(selectedAmenityDetail.attr)", Object.keys(selectedAmenityDetail.attr[selectedAttr]));

                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    console.log("a---", a)
                                                                                                }
                                                                                                {
                                                                                                    a.toLowerCase() === "units" ?
                                                                                                        <div className="attbox">
                                                                                                            <label>Units</label>
                                                                                                            {/* <select
                                                                                                                onChange={onAttrDataChange}
                                                                                                                id="units"
                                                                                                            >
                                                                                                                {
                                                                                                                    config.attrMeasurementUnits.map((unit, index) =>{
                                                                                                                        return (
                                                                                                                            <option key={index} value={unit.value} selected={selectedAmenityDetail.attr[el].units == unit.value} >{unit.label}</option>    
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </select> */}
                                                                                                            <SingleSelect
                                                                                                                formData={selectedAmenityDetail.attr[el]}
                                                                                                                formKey="units"
                                                                                                                onDataChange={onAttributeDataChange}
                                                                                                                // label="units"
                                                                                                                placeholder="units"
                                                                                                                isRequired={false}
                                                                                                                options={config.attrMeasurementUnits}
                                                                                                            />
                                                                                                        </div>
                                                                                                        :
                                                                                                        a.toLowerCase() === "_id" || a.toLowerCase() === "inventorymasterattrslug" || a.toLowerCase() === "noofchangesyearly" || a.toLowerCase() === "cpi" || a.toLowerCase() === "materialcost" || a.toLowerCase() === "opportunitiestosee"
                                                                                                            ?
                                                                                                            ""
                                                                                                            :
                                                                                                            <div className="attbox" key={aIndex + "-" + index}>

                                                                                                                <label>{a ? titleCase(a) : ""}</label>
                                                                                                                <Numberfield
                                                                                                                    formData={selectedAmenityDetail.attr[selectedAttr]}
                                                                                                                    formKey={a}
                                                                                                                    onDataChange={onAttrDataChange}
                                                                                                                    placeholder='100'
                                                                                                                    isRequired={false}
                                                                                                                    allowDecimal={a.toLowerCase() === "quantity" ? false : true}
                                                                                                                />
                                                                                                            </div>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <input onClick={onAttrSubmit} className="btn btn-outline" name="Submit" value="Add Inventory" type="button" />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </article>
            </div>
            <div className={`email-veri email-pop ${showDeleteModal === true ? "active" : ""}`}> <a onClick={closeDeleteModal} href="javascript:void(0);" className="close-pop"><img src={close} /></a>
                <h2 className="htx2">Delete Inventory</h2>
                <p className="ptx2">Remove the inventories you wish to delete</p>
                <div className="dele-invetrybox">
                    {
                        (quantityList && quantityList.length > 0) &&
                        quantityList.map((el, index) => {
                            return (
                                <div className="dele-invetry" key={index}>
                                    <h3>{el.label}</h3>
                                    {/* <p>14x32x12 <b>Feet</b></p> */}
                                    {
                                        quantityList.length > 1 &&
                                        <a
                                            onClick={() => !aminityDeleteInProgress && removeSelectedAmenity(index, el.label)}
                                            href="javascript:void(0);"
                                            className="remove-invetry">
                                            <img src={deleteImg} />
                                        </a>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <input onClick={closeDeleteModal} name="Done" value="Done" className="btn btn-orange remove" type="button" />
            </div>

            <ConfirmDialog
                togglePopup={toggleAmenityDeleteConfirm}
                onModalClose={amenityConfirmClose}
                componentName={selectedDeleteAmenityName}
                isLoading={aminityDeleteInProgress}
            />

            <ConfirmDialog
                togglePopup={toggleCustomAttrDeleteConfirm}
                onModalClose={attrConfirmClose}
                componentName={selectedCustomAttrName}
            />

            <ConfirmDialog
                togglePopup={toggleMainAmenityDeleteConfirm}
                onModalClose={mainAmenityConfirmClose}
                componentName={selectedMainAmenityName}
            />
            {onBoarding && <Steps
                enabled={onBoarding2.stepsEnabled}
                steps={onBoarding2.steps}
                initialStep={onBoarding2.initialStep}
                onComplete={hanldeOnboardingComplete2}
                onExit={exitOnBoarding2}
                options={onBoarding2.options}
                onBeforeChange={handleBeforeChange}
            />}

        </>
    )
})

export default AmenityDetails
