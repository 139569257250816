import {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useContext,
    createRef,
  } from "react"
  
import { objectDeepClone } from "../../../../../service/helper"

import AmenityCard from "../../../../../components/addon/amenityCard/amenityCard"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"
import InventoryModal from "../../../../../components/addon/inventoryModal"

import close from "../../../../../assets/images/close-1.svg"
import star from "../../../../../assets/images/star.svg"
import addBox from "../../../../../assets/images/add-bx.svg"
import check from "../../../../../assets/images/check.svg"
import config from "../../../../../service/config"

import DataContext from "../../../../../service/contextProvider"
import AppContextObject from "../../../../../common/context/common"

interface Props {
    availableFacilities? : any
    hideSelectAll? : boolean
    showModal? : boolean,
    isEdit? : boolean,
    isCustomEdit? : boolean,
    isModal? : true,
    selectedInventories? : any,
    onFacilitiesChange?: Function
    showPrompt?: boolean,
    showQuitButton?: any,
    inDashboard?: boolean
}

const AvailabelFacilities = forwardRef(
    ({
        hideSelectAll,
        showModal,
        availableFacilities,
        isEdit,
        isCustomEdit,
        selectedInventories,
        onFacilitiesChange,
        showPrompt,
        showQuitButton,
        inDashboard
    }: Props, ref) => {
    const parentData = useContext <any> (DataContext)
    const context = useContext <any> (AppContextObject)

    let [overlay, setOverlay] = useState(false)
    let [selectedEntityName, setSelectedEntityName] = useState("")
    let [selectedAmenityIdx, setSelectedAmenityDetailIdx] = useState(null)
    let [toggleAmenityDeleteModal, setToggleAmenityDeleteModal] = useState(false)
    let [toggleAmenityDeselectModal, setToggleAmenityDeselectModal] = useState(false)
    let [activityInfoRefs, setActivityInfoRefs] = useState([])
    let [dataLength, setDataLength] = useState(0)

    let [facilities, setFacilities] = useState(availableFacilities)
    let [showNewCard, setShowNewCard] = useState(false)
    let [selectAll, setSelectAll] = useState(false)
    let [newFacilityName, setNewFaciltyName] = useState("")
    let [allAmenities, setAllAmenities] = useState(config.schoolAmenities)

    const [customInventoryData, setCustomInventoryData] = useState<any>({name: ""})
    const [isCustomInventoryEdit, setIsCustomInventoryEdit] = useState(false)
    const [toggleInventoryModal, setToggleInventoryModal] = useState(false)
    const [amenityIdxToEdit, setAmenityIdxToEdit] = useState(0)
    const [inventoriesSelected, setInventoriesSelected] = useState(selectedInventories)

    const [defaultAttrs] = useState({
        quantity: 0,
        units: "feet",
        length: 0,
        height: 0,
        breadth: 0,        
    })

    let [defaultObj] = useState({
        name: "",
        _checked: true,
        _custom: true,
        quantity: 1,
        data: [{
            name: "",
            customName: "",
            images: [],
            attr: {},
        }],
    })
    // error and edit functionality
    const [error, setError] = useState(false)
    const [sameNameError, setSameNameError] = useState(false)
    const [showInput, setShowInput] = useState(false)
    const [toBeEdited, setToBeEdited] = useState(0)
    const [errorOnEdit, setErrorOnEdit] = useState(false)
    const [schoolExistingInventories, setSchoolExistingInventories] = useState([])

    const onEdit = (index) => {
        setShowInput(true)
        setToBeEdited(index)
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        init()
        setInventoriesSelected(selectedInventories)
    }, [selectedInventories])

    useEffect(() => {
        if(inDashboard){
            setCustomInventoryData({name: "",inventoryCategory: ""})
            getschoolExistingInventories()
        }
    }, []);
    
    useEffect(() => {
        setDataLength(facilities.length)
        if (onFacilitiesChange) {
            onFacilitiesChange(facilities.filter((e) => e._checked === true))
        }
    }, [facilities])

    useEffect(() => {
        setActivityInfoRefs(activityInfoRefs => (
            Array(dataLength).fill(0).map((_, i) => activityInfoRefs[i] || createRef())
        ))
    }, [dataLength])

    useEffect(() => {
        if (showModal === false && selectedInventories && selectedInventories.length === 0) {
            setFacilities(availableFacilities)
        }
        if (showModal === true) {
            setFacilities(availableFacilities)
            // init()
        } else {
            setNewFaciltyName("")
            setShowNewCard(false)
        }
    }, [showModal, availableFacilities])

    const init = () => {
        if (
            selectedInventories &&
            selectedInventories.length > 0
            ) {
            const arr = objectDeepClone(availableFacilities)
            arr.forEach((el, i) => {
                const found = selectedInventories.find(
                    (e) => e.name.toLowerCase().trim() === el.name.toLowerCase().trim()
                )
                if (found) {
                    arr[i] = found
                }
            })
            const custom = selectedInventories.filter(
                (el) => el._custom === true
            )
            if(showModal) {
                setAllAmenities(prev=>[...prev, ...custom])
            }
            arr.push(...custom)
            let uniqeArr = arr.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i)
            if(isEdit === true){
                setFacilities(uniqeArr)
            }else if(isCustomEdit === true){
                setFacilities(uniqeArr)
            }else{
                setFacilities(arr)
            }
        }
    }

    useEffect(() => {
        // This useEffect is for.. if user selects all inventories manually by clicking on each
        // then change select all state to true.
        let arr = objectDeepClone(facilities)
        let result = arr.filter((el) => el["_checked"] !== true && el["_custom"] !== true)
        if(result.length < 1){
            setSelectAll(true)
        }else{
            setSelectAll(false)
        }
    }, [facilities])

    const getschoolExistingInventories = async () =>{
        let resp = await context.apis.public.getInventoriesBySchoolId(
            context.validator, {school: context.user.school}
        )
        if(resp && resp.done && resp.response && resp.response.rows){
            setSchoolExistingInventories(resp.response.rows)
        }
    }

    const onCardClick = (data, index) => {
        if (data["_custom"] !== true) {
            const arr = objectDeepClone(facilities)
            if (!arr[index]._checked) {
                arr[index]._checked = !arr[index]._checked
                setFacilities(arr)
                // setSelectAll(!check)
            } else {
                // setSelectAll(!check)
                setSelectedEntityName(data.name)
                setSelectedAmenityDetailIdx(index)
                setOverlay(true)
                setToggleAmenityDeselectModal(true)
            }
        }
    }

    const onTextChange = (event) => {
        setNewFaciltyName(event.target.value)
        setError(false)
    }

    const addNewFacility = () => {
        if (showNewCard === false) {
            setShowNewCard(true)
        }
    }

    const removeAmenity = (index, name) => {
        setSelectedEntityName(name)
        setSelectedAmenityDetailIdx(index)
        setOverlay(true)
        setToggleAmenityDeleteModal(true)
    }

    const onSelectAll = (event) => {
        const checked = event.target.checked
        setSelectAll(checked)
        let arr = objectDeepClone(facilities)
        if (checked === true) {
            arr.forEach((el) => {
                el["_checked"] = true
            })
            setFacilities(arr)
        } else {
            arr.forEach((el, ind) => {
                if (el["_custom"] !== true) {
                    el["_checked"] = false
                }
                if (isEdit === true) {
                    let removeArr = []
                    arr[ind].data.map(inv=>{
                        if (inv._id) {
                            removeArr.push(inv._id)
                        }
                        if(inv.images.length > 0){
                            parentData.data.removeUploadedImageArray.push([inv])
                        }
                    })
                    parentData.setData({
                        ...parentData.data,
                        removeInventoriesArray: removeArr
                    })
                }
            })           
            setFacilities(arr)
        }
    }

    const amenityConfirmClose = async (confirm) => {
        if (confirm) {
            const arr = objectDeepClone(facilities)
            if(isEdit === true){
                if(arr[selectedAmenityIdx].type !== undefined){
                    arr[selectedAmenityIdx].data.map(inv=>{
                        parentData.data.removeInventoriesArray.push(inv._id);
                        if(inv.images.length > 0){
                            parentData.data.removeUploadedImageArray.push([inv])
                        }
                    })
                }
            }
            let removeImage = []
            arr[selectedAmenityIdx].data.map(inv=>{
                if(inv.images.length > 0){
                    removeImage.push(inv)
                }
            })
            if(isEdit === false){
                if(removeImage.length > 0){
                    deleteMultipleUploadedImages(removeImage)
                }
            }
            console.log("context--->>>>",context);
            if(context.user){
                console.log("arr[selectedAmenityIdx]----",arr[selectedAmenityIdx]);
                let response = await context.apis.public.upsertSchoolInventoriesDetailsBySchoolId(
                    context.validator, {
                    "school": context.user.school,
                    "user": context.user._id,
                    "amenities": [],
                    "removeInventoriesArray": [arr[selectedAmenityIdx]._id],
                    }, {}
                )
                console.log("response--->",response);
            }
            if(!context?.user || (context?.user && ["backendAdmin", "backendFOS", "backendTech","backendOperations"].includes(context?.user?.role))){
                let selectedInventoriesForAlgo = parentData.data.algorithmData.inventories;
                let filterArr = selectedInventoriesForAlgo.filter(element=> arr[selectedAmenityIdx].name !== element.parentInventory)

                console.log("arr[selectedAmenityIdx]>",arr[selectedAmenityIdx]);
                console.log("selectedInventoriesForAlgo>",selectedInventoriesForAlgo,arr, filterArr);

                if (parentData && parentData.setData) {
                    parentData.setData({
                        ...parentData.data,
                        algorithmData: {
                            ...parentData.data.algorithmData,
                            inventories: filterArr
                        }
                    })
                }

                let resp = await context.apis.public.getOnboardingRevenue(
                    context.validator, {
                        ...parentData.data.algorithmData,
                        inventories: filterArr
                    }
                )
                if(resp && resp.done){
                    if(parentData && parentData.setNetRevenue){
                        parentData.setNetRevenue(resp.netRevenue || 0)
                    }
                    console.log("netRevenue, prevNetRevenue showPrompt",showPrompt);
                    if(showPrompt){
                        if(parentData && parentData.setPrevNetRevenue){
                            parentData.setPrevNetRevenue(resp.netRevenue || 0)
                        }
                    }
                   
                }

                console.log("algo resp->>>>", resp);
            }
            
            const arr2 = objectDeepClone(selectedInventories)
            let index = arr2.findIndex(x => x.name === selectedEntityName);
            console.log("arr", arr, arr2, facilities)
            if (index !== -1) {
                arr2.splice(index, 1);
              }
            setInventoriesSelected(arr2)
            arr.splice(selectedAmenityIdx, 1)
            setFacilities(arr)
        }
        setOverlay(false)
        setToggleAmenityDeleteModal(false)
    }

    const deleteMultipleUploadedImages = (amenitydata) =>{
        if(amenitydata !== []){
            let imagesKeyArray = [];
            amenitydata && amenitydata.map(am=>{
                am.images.map(img=>{
                    imagesKeyArray.push({Key: img.key})
                })
            })
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                },
                body: JSON.stringify({ keys: imagesKeyArray && imagesKeyArray })
            }

            fetch('/api/deleteMultipleUploadedFiles', requestOptions)
            .catch(err=>console.log(err))    
        }
    }

    const amenityConfirmDeselect = async (confirm) => {
        if (confirm) {       
            const arr = objectDeepClone(facilities)
            if(isEdit === true){
                if(arr[selectedAmenityIdx].type !== undefined){
                    arr[selectedAmenityIdx].data.map(inv=>{
                        parentData.data.removeInventoriesArray.push(inv._id);
                        if(inv.images.length > 0){
                            parentData.data.removeUploadedImageArray.push([inv])
                        }
                    })
                }
            }
            let removeImage = []
            arr[selectedAmenityIdx].data.map(inv=>{
                if(inv.images.length > 0){
                    removeImage.push(inv)
                }
            })
            if(isEdit === false){
                if(removeImage.length > 0){
                    deleteMultipleUploadedImages(removeImage)
                }
            }
            const foundAmenity = availableFacilities.find(e => e.name === arr[selectedAmenityIdx].name)
            console.log("foundAmenity", foundAmenity, availableFacilities, facilities)
            if (foundAmenity) {
                arr[selectedAmenityIdx] = objectDeepClone(foundAmenity)
            }
            setFacilities(arr)
            setSelectedAmenityDetailIdx(null)

            if(!showQuitButton && (!context?.user || (context?.user && ["backendAdmin", "backendFOS", "backendTech","backendOperations"].includes(context?.user?.role)))){
                let selectedInventoriesForAlgo = parentData.data.algorithmData.inventories;
                let filterArr = selectedInventoriesForAlgo.filter(element=> arr[selectedAmenityIdx].name !== element.parentInventory)

                console.log("arr[selectedAmenityIdx]>",arr[selectedAmenityIdx]);
                console.log("selectedInventoriesForAlgo>",selectedInventoriesForAlgo, filterArr);

                if (parentData && parentData.setData) {
                    parentData.setData({
                        ...parentData.data,
                        algorithmData: {
                            ...parentData.data.algorithmData,
                            inventories: filterArr
                        }
                    })
                }

                let resp = await context.apis.public.getOnboardingRevenue(
                    context.validator, {
                        ...parentData.data.algorithmData,
                        inventories: filterArr
                    }
                )
                if(resp && resp.done){
                    if(parentData && parentData.setNetRevenue){
                        parentData.setNetRevenue(resp.netRevenue || 0)
                    }
                    console.log("netRevenue, prevNetRevenue showPrompt",showPrompt);
                    if(showPrompt){
                        if(parentData && parentData.setPrevNetRevenue){
                            parentData.setPrevNetRevenue(resp.netRevenue || 0)
                        }
                    }
                   
                }

                console.log("algo resp->>>>", resp);
            }
        }
        setOverlay(false)
        setToggleAmenityDeselectModal(false)        
    }

    useImperativeHandle(ref, () => ({
        fetchFacilities() {
            return facilities.filter((e) => e._checked === true)
        },
    }))

    const onCustomInventorySave = (data, oldName) => {
        setOverlay(false)
        if(inDashboard){
            setCustomInventoryData({name: data.name, inventoryCategory: data.inventoryCategory})
        }else{
            setCustomInventoryData(data)
        }
        setToggleInventoryModal(false)
        if (isCustomInventoryEdit === false) {
            let obj = objectDeepClone(defaultObj)
            if (data.hasOwnProperty("inventoryCategory")) {
                obj["type"] = data.inventoryCategory    
            }
            obj["name"] = data.name
            obj.data[0].name = data.name + " 1"
            obj.data[0].attr[data.name] = objectDeepClone(defaultAttrs)
            setFacilities([...facilities, {...obj}])
        } else {
            const arr = [...facilities]
            const arr2 = [...inventoriesSelected]
            let index = arr2.findIndex(x => x.name === customInventoryData.name);
            arr[amenityIdxToEdit].name = data.name
            arr[amenityIdxToEdit].type = data.inventoryCategory
            
            arr[amenityIdxToEdit].data.forEach(e => {
                if (e.attr.hasOwnProperty(oldName)) {
                    if (oldName !== data.name) {
                        e.attr[data.name] = {...e.attr[oldName]}
                        delete e.attr[oldName]
                    }
                }
            })    
            if (index !== -1) {
                arr2[index].name = data.name
                arr2[index].type = data.inventoryCategory
                arr2[index].data.forEach(e => {
                    if (e.attr.hasOwnProperty(oldName)) {
                        if (oldName !== data.name) {
                            e.attr[data.name] = { ...e.attr[oldName] }
                            delete e.attr[oldName]
                        }
                    }
                })
                setInventoriesSelected(arr2)
            setInventoriesSelected(arr2)       
                setInventoriesSelected(arr2)
            setInventoriesSelected(arr2)       
                setInventoriesSelected(arr2)
            }
            setFacilities(arr)
        }
    }

    const addNewInventory = () => {
        setIsCustomInventoryEdit(false)
        setOverlay(true)
        setToggleInventoryModal(true)
        setCustomInventoryData({name: ""})
    }

    const updateCustomAmenity = (data, index) => {
        setAmenityIdxToEdit(index)
        setIsCustomInventoryEdit(true)
        setOverlay(true)
        setToggleInventoryModal(true)
        if(inDashboard){
            setCustomInventoryData({name: data.name, inventoryCategory: data.type})
        }else{
            setCustomInventoryData({name: data.name})
        }
    }

    const onInventoryModalClose = () => {
        setOverlay(false)
        setToggleInventoryModal(false)
    }

    const renderAmenityList = () => {
        return (
            <>
                <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
                {
                    facilities && facilities.length > 0 && 
                    facilities.sort((a, b) => a.name.localeCompare(b.name)).map((el, index) => {
                        return (
                            <div
                                key={index}
                                className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6'
                            >
                                <AmenityCard
                                    ref={activityInfoRefs[index]}
                                    data={el}
                                    index={index}
                                    onCardClick={onCardClick}
                                    removeAmenity={removeAmenity}
                                    onEdit={updateCustomAmenity}
                                    showInput={showInput}
                                    error={errorOnEdit}
                                    setError={setErrorOnEdit}
                                    toBeEdited={toBeEdited}
                                    sameNameError={sameNameError}
                                />
                            </div>
                        )
                    })
                }
                {showNewCard === true && (
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                    <div className='facili-avai1'>
                        <img className='checkcom' src={check} />{" "}
                        <a href='javascript:void(0)' className='remove-icon'>
                            <img src={close} />
                        </a>{" "}
                        <img className='checkcom star' src={star} />
                    </div>
                </div>
                )}
                {
                <div
                    onClick={addNewInventory}
                    className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6'
                >
                    <div id="customAdd" className='facili-avai1 add'>
                        {" "}
                        <img src={addBox} />{" "}
                    </div>
                </div>
                // (showNewCard === true || showInput === true) ?
                }
            </>            
        )
    }

    return (
        <>
            {
                hideSelectAll !== true &&
                <>
                    {showQuitButton && <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-20"><h2>Add New Inventory</h2></div>}
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        <p className="ptx">{showQuitButton ? "Add more inventory from the existing options or create a new one" : "Select one or multiple inventories"}</p>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="checkbox">
                            <input
                                checked={selectAll}
                                type='checkbox'
                                id='selectAllCheckbox'
                                onChange={onSelectAll}
                            />
                            <label htmlFor='selectAllCheckbox'>Select All</label>
                        </div>
                    </div>
                </>
            }
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
                <div className="facili-avai-in clearfix">
                    <div className="row">
                        {
                            renderAmenityList()
                        }
                    </div>
                </div>
            </div>
            <ConfirmDialog
                togglePopup={toggleAmenityDeleteModal}
                onModalClose={amenityConfirmClose}
                componentName={selectedEntityName}
            />

            <ConfirmDialog
                togglePopup={toggleAmenityDeselectModal}
                onModalClose={amenityConfirmDeselect}
                componentName={selectedEntityName}
                confirmTitle="Are you sure you want to remove?"
            />

            <InventoryModal
                data={customInventoryData}
                parentCallback={onCustomInventorySave}
                isEdit={isCustomInventoryEdit}
                onModalClose={onInventoryModalClose}
                togglePopup={toggleInventoryModal}
                inDashboard={inDashboard}
                schoolExistingInventories={schoolExistingInventories}
                existingAmenities={(facilities && facilities.length > 0) ? facilities : []}
                selectedInventories={(inventoriesSelected && inventoriesSelected.length > 0) ? inventoriesSelected : []}
            />
        </>
    )
})

export default AvailabelFacilities
