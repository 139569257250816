import { useState, useContext, useEffect, useRef } from "react"
import SimpleReactValidator from "simple-react-validator";
import { Link, useHistory } from "react-router-dom"
import moment from 'moment';
import arrowUp from "../../../assets/images/arrow-up.svg"
import arrowDown from "../../../assets/images/arrow-down.svg"
import errorSVG from "../../../assets/images/error.svg";
import closeCustome from "../../../assets/images/close-1.svg";
import { ExportToCsv } from 'export-to-csv';

import AppContextObject from "../../../common/context/common"

import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import CampaignTable from "./components/campaign/CampaignTable"
import SingleSelect from "../../../components/formFields/singleSelect/singleSelect"
import SearchInput from "./components/SearchInput";
import config from "../../../service/config";
import CampaignRejetionModal from "./components/CampaignRejectionModal";
import CustomDatePicker from "../../../components/formFields/datePicker";
import BackendTopBar from "./components/backendTopBar";
import BackendBackBtn from "./components/backendBackBtn";
import ArrowView from "../../../assets/images/arrow-view.svg"
import { on } from "events";
import downloadSvg from "../../../assets/images/backendAdmin/download-svg.svg";
import { formatDateShowMonthBackend } from "../../../service/helper";
import { downloadFile, numDifferentiation, titleCase } from '../../../service/helper';
import rupeeSymbol from '../../../assets/images/rupee-symbol.svg';
const { detect } = require('detect-browser');

var backendCampaignListGlobalVar = 1

const CampaignList = ({onlyActiveCampaign}) => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const [overlay, setOverlay] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [formData, setFormData] = useState({ dateFilter: "", dateCreatedFilter: "" });
    const [campaigns, setCamnpaigns] = useState<any>([]);
    const [category, setCategory] = useState([]);
    const [region, setRegion] = useState([]);
    const [states, setStates] = useState([])
    const [location, setLocation] = useState([]);
    const [campaignStat, setCampaignStat] = useState<any>({});
    const [filterType, setFilterType] = useState("");
    const [filterStatus, setFilterStatus] = useState([]);
    const [selectedTab, setSelectedTab] = useState("all");
    const [allCount, setAllCount] = useState<any>({});
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState<any>([0]);
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentpage] = useState(1);
    const [isApproved, setIsApproved] = useState(null);
    const [flag, setFlag] = useState(false);
    const [openRejectedModal, setOpenRejectedModal] = useState(false);
    const [rejectionMsg, setRejectionMsg] = useState({ reason: "" });
    const [campaignId, setCampaignId] = useState<any>({});
    const [update, forceUpdate] = useState(false);
    const [customSheduledFilter, setCustomSheduledFilter] = useState(false);
    const [customCreatedFilter, setCustomCreatedFilter] = useState(false);
    const [stateByRegion, setStateByRegion] = useState([])
    const [citiesByState, setCitiesByState] = useState([]);
    const [tabStatus, setTabStatus] = useState('');
    const [openShoolFilter, setOpenShoolFilter] = useState(false);
    const [openStatusFilter, setOpenStatusFilter] = useState(false);
    const [openPaymentFilter, setOpenPaymentFilter] = useState(false);
    const [isLoading,setIsLoading]=useState(false);
    const [scheduleSearch,setScheduleSearch]=useState(false);
    const [createSearch,setCreateSearch]=useState(false);
    const [acceptingCampaign, setAcceptingCampaign] = useState(false)
    const [rejectingCampaign, setRejectingCampaign] = useState(false)
    const [formSheduledDataEmpty, setFormSheduledDataEmpty] = useState(false)
    const [formCreatedDataEmpty, setFormCreatedDataEmpty] = useState(false)
    const [initialiseCount, setInitialiseCount] = useState(false)
    const [sortingData, setSortingData] = useState({ _id: -1 })
    const [formSheduledData, setFormSheduledData] = useState({
        startSheduledDate: "",
        endSheduledDate: ""
    })
    const [formSheduledDataDiffFormat, setFormSheduledDataDiffFormat] = useState({
        startSheduledDate: "",
        endSheduledDate: ""
    })
    const [formCreatedData, setFormCreatedData] = useState({
        startCreatedDate: "",
        endCreatedDate: ""
    })
    const [formCreatedDataDiffFormat, setFormCreatedDataDiffFormat] = useState({
        startCreatedDate: "",
        endCreatedDate: ""
    })
    const [createedFilterErr, setCreatedFilterErr] = useState({
        startDate: false,
        endDate: false
    })
    const [createedFilterErrMsg, setCreatedFilterErrMsg] = useState({
        startDateMsg: "",
        endDateMsg: ""
    })
    const [sheduledFilterErr, setSheduleddFilterErr] = useState({
        startDate: false,
        endDate: false
    })
    const [sheduledFilterErrMsg, setSheduledFilterErrMsg] = useState({
        startDateMsg: "",
        endDateMsg: ""
    })
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>

            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    const [isChrome, setIsChrome] = useState(true)
    const [schoolId, setSchoolId] = useState<any>()
    const [downloadSchoolLoading, setDownloadSchoolLoading] = useState(false);
    const hideModal = () => {
        setOpenRejectedModal(false);
        setOverlay(false);
        setRejectionMsg({ reason: "" });
    }
    useEffect(() => {
        if(customSheduledFilter && (formSheduledData.startSheduledDate === "" || formSheduledData.endSheduledDate === "")){
            setFormSheduledDataEmpty(true)
        }
        else{
            setFormSheduledDataEmpty(false)
        }
    }, [formSheduledData, formData.dateFilter])
    useEffect(() => {
        if(customCreatedFilter && (formCreatedData.startCreatedDate === "" || formCreatedData.endCreatedDate === "")){
            setFormCreatedDataEmpty(true)
        }
        else{
            setFormCreatedDataEmpty(false)
        }
    }, [formCreatedData, formData.dateCreatedFilter])
    useEffect(() => {
        if (update === true) {
            formValidator.current.showMessages()
        } else {
            formValidator.current.hideMessages()
        }
    }, [update])

    useEffect(() => {
        if(onlyActiveCampaign === false){
            getCampaignStat();
        }
        let state:any = history.location.state
        if(state && state?.fromPage === "fos-active-schools" && state?.schoolId && onlyActiveCampaign){
            console.log("reached")
            setSchoolId(state.schoolId)
        }
        const browser = detect();
        if (browser) {
            // console.log("browser...", browser.name)
            setIsChrome(browser.name === "chrome")
        }
    }, [])

    useEffect(() => {
        console.log(formData.dateFilter,scheduleSearch,formData.dateCreatedFilter,createSearch)
        let state:any = history.location.state
        if(state && state?.fromPage === "fos-active-schools" && state?.schoolId && onlyActiveCampaign){
            console.log("reached")
            getCampaignList(state.schoolId);
        }
        else{
            if((formData.dateFilter!=='custom' && formData.dateCreatedFilter!=='custom')){
                console.log('111111')
                getCampaignList();
            }else if((formData.dateFilter==='custom' && scheduleSearch===true)){
                console.log('22222')
                getCampaignList();
            }else if((formData.dateCreatedFilter==='custom' && createSearch===true)){
                console.log('3333333')
                getCampaignList();
            }
            else if((formData.dateFilter==='last_thirty_day' || formData.dateFilter==='this_month' ||
            formData.dateFilter==='last_month' || formData.dateFilter==='last_three_month' ||
            formData.dateFilter==='last_six_month') && formData.dateCreatedFilter==='custom'){
                getCampaignList();
            }
            else if((formData.dateCreatedFilter==='last_thirty_day' || formData.dateCreatedFilter==='this_month' ||
            formData.dateCreatedFilter==='last_month' || formData.dateCreatedFilter==='last_three_month' ||
            formData.dateCreatedFilter==='last_six_month') && formData.dateFilter==='custom'){
                getCampaignList();
            }
        }
        // else if((formData.dateFilter==='custom' && formData.dateCreatedFilter!=='custom')){
        //     // console.log('44444444')
        //     // if(formData.dateFilter==='custom' && scheduleSearch===true){
        //         console.log('555555555')
        //         getCampaignList();

        //     // }
        // }
        // else if((formData.dateFilter!=='custom' && formData.dateCreatedFilter==='custom')){
        //     // console.log('666666666')
        //     // if(formData.dateCreatedFilter==='custom' && createSearch===true){
        //         console.log('77777777')
        //         getCampaignList();

        //     // }
        // }
    }, [currentPage, scheduleSearch, createSearch, searchKeys, formData, filterType, paymentStatus, category, location, isApproved, flag, filterStatus, tabStatus, formSheduledData, formCreatedData])
    useEffect(() => {
        if(currentPage !== 1){
            setCurrentpage(1)
        }
    }, [scheduleSearch, createSearch, searchKeys, formData, filterType, paymentStatus, category, location, isApproved, flag, filterStatus, tabStatus, formSheduledData, formCreatedData])
    // useEffect(() => {
    //     getCampaignList()
    // }, [])

    useEffect(() => {
        if (region.length > 0) {
            fetchStateByRegion();
        }
    }, [region])
    useEffect(() => {
        if (states.length > 0) {
            fetchCitiesByState();
        }
    }, [states])

    const fetchCitiesByState = async () => {
        let resp = await context.apis.public.getCitiesByState(
            context.validator, { states: states }, {}
        )
        console.log(resp);
        if (resp && resp.done) {
            let cities = []
            Array.isArray(resp.response.rows) && resp.response.rows.forEach(row => {
                cities = [...cities, ...row.cities]
            })
            setCitiesByState([...cities])
            console.log(cities);
        }
    }

    const fetchStateByRegion = async () => {
        let publicContextInitiator = new PublicContextInitiator(context);
        let resp = await publicContextInitiator.getStatesByCountryRegion(
            context.validator, { countryRegion: region }, {}
        )
        console.log('cities resp======>', resp)
        if (resp && resp.done) { 
            let states = []
            Array.isArray(resp.response.rows) && resp.response.rows.forEach(row => {
                states = [...states, ...row.states]
            })
            setStateByRegion([...states])
        }
    }
    const acceptRejectPayment = async (val, campaignId) => {
        setCampaignId(campaignId);
        if (val === 'rejected') {
            setOpenRejectedModal(true);
            setOverlay(true);
            // setFlag(!flag);
        }
        else if (val === 'accepted') {
            // setFlag(!flag);
            setAcceptingCampaign(true)
            let resp = await context.apis[context.user.role].updateCampaignStatus(
                context.validator, { _id: campaignId._id }, { isApproved: true, campaignStatus: 'in-review' }
            )
            console.log('approved resp======>', resp)
            if (resp && resp.done) {
                setAcceptingCampaign(false)
                setFlag(!flag);
            }
        }
    }
    const rejectPayment = async () => {
        const result = formValidator.current.allValid();
        if (!result) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages();
            setRejectingCampaign(true)
            let resp
            if(campaignId.campaignCreativesRevised && campaignId.campaignCreativesRevised.length > 0){
                resp = await context.apis[context.user.role].updateCampaignStatus(
                    context.validator, { _id: campaignId._id }, { isApproved: false, campaignStatus: 'in-review', creativeReworkNeeded: true, rejectionMessage: rejectionMsg.reason }
                )
            }
            else{
                resp = await context.apis[context.user.role].updateCampaignStatus(
                    context.validator, { _id: campaignId._id }, { isApproved: false, campaignStatus: "rejected", rejectionMessage: rejectionMsg.reason }
                )
            }
            console.log('reject resp======>', resp)

            if (resp && resp.done) {
                setOpenRejectedModal(false);
                setAcceptingCampaign(false)
                setRejectingCampaign(false)
                setRejectionMsg({ reason: "" });
                setRejectingCampaign(false);
                setOverlay(false);           
                setFlag(!flag);
            }
        }
    }

    const getCampaignStat = async () => {
        let publicContextInitiator = new PublicContextInitiator(context);
        let resp = await publicContextInitiator.getCampaignStatsForBackendAdmin(
            context.validator, {}, {}
        )
        console.log('stat resp======>', resp)
        if (resp && resp.done) {
            setCampaignStat(resp.response)
        }
    }
    // useEffect(() => {
    //     getCount();
    // }, [])
    // const getCount = async () => {
    //     let resp = await context.apis.public.getCampaignsByBrandExternal(
    //         context.validator, {},
    //         { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
    //     )

    //     console.log('campaigns resp======>', resp)

    //     if (resp && resp.done) {
    //         setAllCount(resp['allCounts'])
    //     }
    // }

    const getCampaignList = async (_id?) => {
        setIsLoading(true);
        var localVar = Math.random()
        backendCampaignListGlobalVar = localVar
        let resp;
        if(filterStatus.length > 0){
            if(onlyActiveCampaign === true && context.user.role === "backendFOS") {
                resp = await context.apis[context.user.role].getCampaignsBySchoolExternal(
                    context.validator, {schoolId: _id}, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
                )
            }
            else{
                resp = await context.apis.public.getCampaignsByBrandExternal(
                    context.validator, {
                    searchValue: searchKeys, dateFilter: formData.dateFilter, dateCreatedFilter: formData.dateCreatedFilter,
                    startDate: formSheduledData, createdDate: formCreatedData, status: filterStatus, paymentStatus: paymentStatus,
                    school: { category: category, city: location }, tabStatus: tabStatus
                },
                    { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
                )
            }
        }
        else{
            if(onlyActiveCampaign === true && context.user.role === "backendFOS") {
                resp = await context.apis[context.user.role].getCampaignsBySchoolExternal(
                    context.validator, {schoolId: _id}, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
                )
            }
            else{
                resp = await context.apis.public.getCampaignsByBrandExternal(
                    context.validator, {
                    searchValue: searchKeys, dateFilter: formData.dateFilter, dateCreatedFilter: formData.dateCreatedFilter,
                    startDate: formSheduledData, createdDate: formCreatedData, status: ['in-review', 'rejected', 'accepted', 'executed', 'on-hold', 'ongoing', "scheduled"], paymentStatus: paymentStatus,
                    school: { category: category, city: location }, tabStatus: tabStatus
                },
                    { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
                )
            }
        }

        console.log('campaigns resp======>', resp)

        if (resp && resp.done) {
            if(localVar === backendCampaignListGlobalVar){
                // setAllCount(resp['allCounts'])
                setCamnpaigns(resp.response.rows)
                let total = Math.ceil(resp.response.count / 10);
                setTotalPageCount(resp.response.count)
                if(initialiseCount === false){
                    setAllCount(resp['allCounts'])
                    setInitialiseCount(true)
                }
                else{
                    setAllCount({...allCount, [selectedTab === "in-review" ? "inReview" : selectedTab === "pending" ? "pendingPayment" : selectedTab] : resp.response.count})
                }
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
        }
        setIsLoading(false)
    }
    const onCategoryChange = (data) => {        
        setCurrentpage(1);
        setCreateSearch(false);
        setCustomSheduledFilter(false);
        setFormData({ ...formData, dateFilter: data.value });
        if (data.value === 'custom') {
            setCustomSheduledFilter(true);
        }
    }
    const onCategoryChange2 = (data) => {
        setScheduleSearch(false)
        setCustomCreatedFilter(false);
        setFormData({ ...formData, dateCreatedFilter: data.value });
        if (data.value === 'custom') {
            setCustomCreatedFilter(true);
        }
    }
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }
    const searchCreateDate = () => {
        var d1 = new Date();
        var d1Date = d1.getFullYear() + '-' + (d1.getMonth() + 1) + '-' + d1.getDate();
        var d2 = new Date(formCreatedData.startCreatedDate);
        var d2Ddate = d2.getFullYear() + '-' + (d2.getMonth() + 1) + '-' + d2.getDate();
        var d3 = new Date(formCreatedData.endCreatedDate);
        var d3Ddate = d3.getFullYear() + '-' + (d3.getMonth() + 1) + '-' + d3.getDate();
        if (formCreatedData.startCreatedDate === '') {
            setCreatedFilterErr({ ...createedFilterErr, startDate: true });
            setCreatedFilterErrMsg({ ...createedFilterErrMsg, startDateMsg: 'Please add a date' })
        }
        else if (formCreatedData.endCreatedDate === '') {
            setCreatedFilterErr({ ...createedFilterErr, endDate: true });
            setCreatedFilterErrMsg({ ...createedFilterErrMsg, endDateMsg: 'Please add a date' })
        }
        else if (d1Date < d2Ddate) {
            setCreatedFilterErr({ ...createedFilterErr, startDate: true });
            setCreatedFilterErrMsg({ ...createedFilterErrMsg, startDateMsg: 'should less than today date1' })
        }
        else if (d1Date < d3Ddate) {
            setCreatedFilterErr({ ...createedFilterErr, endDate: true });
            setCreatedFilterErrMsg({ ...createedFilterErrMsg, endDateMsg: 'should less than today date' })
        }
        else {
            setCustomCreatedFilter(false);
            setFlag(!flag);
        }
    }
    const searchSheduleDate = () => {
        if (formSheduledData.startSheduledDate === '') {
            setSheduleddFilterErr({ ...sheduledFilterErr, startDate: true });
            setSheduledFilterErrMsg({ ...sheduledFilterErrMsg, startDateMsg: 'Please add a date' })
        }
        else if (formSheduledData.endSheduledDate === '') {
            setSheduleddFilterErr({ ...sheduledFilterErr, endDate: true });
            setSheduledFilterErrMsg({ ...sheduledFilterErrMsg, endDateMsg: 'Please add a date' })
        }
        else {
            setCustomSheduledFilter(false);
            setFlag(!flag);
        }
    }
    const onScheduleDateChange = (event, id) => {
        if (isChrome) {
            setFormSheduledData({ ...formSheduledData, [event.target.id]: event.target.value });
            setFormSheduledDataDiffFormat({...formSheduledDataDiffFormat, [event.target.id] : event.target.value});
        }
        else {
            setFormSheduledData({ ...formSheduledData, [id]: moment(event).format("yyyy-MM-DD") });
            setFormSheduledDataDiffFormat({...formSheduledDataDiffFormat, [id] : event})
        }
    }
    const onCreatedDateChange = (event, id) => {
        if (isChrome) {
            setFormCreatedData({ ...formCreatedData, [event.target.id]: event.target.value });
            setFormCreatedDataDiffFormat({...formCreatedDataDiffFormat, [event.target.id]: event.target.value });
        }
        else {
            setFormCreatedData({ ...formCreatedData, [id]: moment(event).format("yyyy-MM-DD") });
            setFormCreatedDataDiffFormat({...formCreatedDataDiffFormat, [id] : event})
        }
    }

    const getExportableData1 = async() => {
        setDownloadSchoolLoading(true)

        console.log("==getExportableData12=====1========>");
        // console.log("=========1==========>",searchKeys);
        // console.log("=========2==========>",formData.dateFilter);
        // console.log("=========3==========>",formData.dateCreatedFilter);
        // console.log("=========4==========>",formSheduledData);
        // console.log("=========5==========>",formCreatedData);
        // console.log("=========6==========>",tabStatus);    
        let resp = await context.apis[context.user.role].getAllCampaignDetailsForBackendUsersNew(
            context.validator, {
                searchValue: searchKeys,
                dateFilter: formData.dateFilter,
                dateCreatedFilter: formData.dateCreatedFilter,
                startDate: formSheduledData, 
                createdDate: formCreatedData, 
                status: ['in-review', 'rejected', 'accepted', 'executed', 'on-hold', 'ongoing', "scheduled"],
                // paymentStatus: paymentStatus,
                tabStatus: tabStatus,
                IsDownload: "true"
            },
                { "sort": { "_id": -1 } }
        )
        console.log("getExportableData12", resp);
    
        let data = resp;
        if (data) {
            let file = data.file;
            downloadFile(file, data.filename);
        }
        setDownloadSchoolLoading(false)
    }

    const getCampaignOverlay = async() => {
        setDownloadSchoolLoading(true);
        const requestOptions = {
        body: JSON.stringify({
            camp_id: '1515',
            inventory_id: [1,2],
            creative_path: ["1676971868901-bisleri1.jpg"]
        }),
        method: 'POST',
        headers: {"Accept": "application/json"},
        };
    
   
    //console.log('getAllSchoolsForBackendUsers ###########', resp)

    fetch("https://api-qa.wisr.co.in", requestOptions)
        .then(response => 
            //console.log("----------response---->",response)
            
            response.json()
            )
        .then(async data => {
            console.log("-----------------data---------check here--->",data);
            
        })
    }


    


    const getAllCampaignDetailsPDF = async() => {

        let resp = await context.apis[context.user.role].getAllCampaignDetailsPDF(
            context.validator, {
                campaignId : '63a4517797afba0008e2b111' ,
                groupBy : "school"
            },{}
        )

        //console.log("------------resp---check here------>",resp);
        //console.log("============2=======================");
        
        //download File Code


        if (resp && resp.done) {
            let file = resp.response;
            downloadFile(file, resp.filename)
        }

    }



    // const getExportableData1 = async () => {

    //     console.log("--getExportableData---check here==campaignList==>")
    //     setDownloadSchoolLoading(true);
    
    //     let resp = await context.apis.public.getAllCampaignDetailsForBackendUsers(
    //         context.validator, {
    //         searchValue: searchKeys,
    //         dateFilter: formData.dateFilter,
    //         dateCreatedFilter: formData.dateCreatedFilter,
    //         startDate: formSheduledData, 
    //         createdDate: formCreatedData, 
    //         status: ['in-review', 'rejected', 'accepted', 'executed', 'on-hold', 'ongoing', "scheduled"],
    //         // paymentStatus: paymentStatus,
    //         tabStatus: tabStatus,
    //         IsDownload: "true"
    //     },
    //         { "sort": { "_id": -1 } }
    //     )
    
    
    //     console.log('getAllSchoolsForBackendUsers ###########', resp)
    //     let data = [];
    //     if (resp.response.rows.length > 0) {
    
    //         await resp.response.rows.forEach((campaign, index) => {
    //             //console.log("campaign====>", campaign);
    
    //             let CampaignDetails = {}
    //             CampaignDetails['SL NO'] = ++index;
    //             CampaignDetails['CAMPAIGN NAME'] = campaign.name;
    //             CampaignDetails['BRANDNAME '] = campaign.brandName;
    //             CampaignDetails['SCHOOL '] = campaign.schools.length;
    //             CampaignDetails['INVENTORIES'] = campaign.campaignData?.inventories?.length;
    //             CampaignDetails['SCHEDULE START DATE'] = campaign.startDate;
    //             CampaignDetails['SCHEDULE END DATE'] = campaign.endDate;
    //             CampaignDetails['CREATED'] = campaign.createdDate;
    //             CampaignDetails['BUDGET'] = campaign.budget.toLocaleString('en-IN');
    //             CampaignDetails['STATUS'] = campaign.campaignStatus === 'rejected' ? 'Rejected' : campaign.campaignStatus === 'ongoing' ? 'Ongoing' : campaign.campaignStatus === "executed" ? "Executed" : campaign.campaignStatus === "scheduled" ? "Scheduled" : campaign.campaignStatus === 'in-review' && campaign.isApproved ? "Approved" : campaign.campaignStatus === "on-hold" ? "On-Hold" : "In-Review";
    //             CampaignDetails['PAYMENT STATUS '] = campaign?.paymentStatus === 'paid' ? 'Paid' : 'Pending';
    
    //             data.push(CampaignDetails);
    //         });
    
    //         const options = {
    //             fieldSeparator: ',',
    //             quoteStrings: '"',
    //             decimalSeparator: '.',
    //             showTitle: false,
    //             filename: 'CampaignDataList',
    //             useTextFile: false,
    //             useKeysAsHeaders: true,
    //             //headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    //         };
    //         setDownloadSchoolLoading(false);
    //         const csvExporter = new ExportToCsv(options);
    //         csvExporter.generateCsv(data);
    //     }
    //     return resp
    // }
 
    // useOutSideClick(modalRef,()=>setOpenShoolFilter(true),openShoolFilter)
    console.log(scheduleSearch)
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12" >
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar from={onlyActiveCampaign  && context.user.role === "backendFOS" && "campaignReporting"} redirectPage={onlyActiveCampaign && context.user.role === "backendFOS" ? true : false} />

                        {/* <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 ${context.user.role === "schoolAdmin" ? "dnone" : ""}`}> */}
                        {onlyActiveCampaign === false &&
                            <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20`}>
                                <div className="inventory-over">
                                    <ul className="inventory-overTabs">
                                        <li>
                                            <a href="javascript:void(0);" className={`tabBorderColor1 ${(campaignStat.ongoingCampaigns - campaignStat.ongoingCampaignsLastMonth) > 0 ? "greenUpPercentage" : (campaignStat.ongoingCampaigns - campaignStat.ongoingCampaignsLastMonth) < 0 ? "redDownPercentage" : "removeArrow"}`}>
                                                <p>Total On-Going Campaigns</p>
                                                <h3>{campaignStat.ongoingCampaigns} <span><b>{campaignStat.ongoingCampaignsLastMonth} <img className={(campaignStat.ongoingCampaigns - campaignStat.ongoingCampaignsLastMonth) >= 0 ? "arrowUp" : "arrowDown"} src={(campaignStat.ongoingCampaigns - campaignStat.ongoingCampaignsLastMonth) >= 0 ? arrowUp : arrowDown} /></b> than last 30 days</span> 
                                                    {
                                                    <Link to={{ pathname: "/backend/backend-details/campaign/specific-campaign", state: { campaignType: "onGoing" } }} className="viewwLink fR">
                                                        View List <img className="arrowView" src={ArrowView} />
                                                    </Link>}
                                                </h3>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" className="tabBorderColor2">
                                                <p>Campaign Requests</p>
                                                <h3 className="row between-xs">{campaignStat.requestedCampaigns} {
                                                    <Link to={{ pathname: "/backend/backend-details/campaign/specific-campaign", state: { campaignType: "request" } }} className="viewwLink fR">
                                                        View List <img className="arrowView" src={ArrowView} />
                                                    </Link>}</h3>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" className="tabBorderColor3">
                                                <p>Rejected Campaigns</p>
                                                <h3 className="row between-xs">{campaignStat.rejectedCampaigns}{
                                                    <Link to={{ pathname: "/backend/backend-details/campaign/specific-campaign", state: { campaignType: "rejected" } }} className="viewwLink fR">
                                                        View List <img className="arrowView" src={ArrowView} />
                                                    </Link>}</h3>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-50 mb-10">
                            <h2 className="htx2 clearfix">Full List of Campaigns</h2>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">
                                {!(onlyActiveCampaign === true && context.user.role === "backendFOS") &&
                                    <>
                                        <ul className={`tabsinventory ${searchInpuOpen ? "tabWidth" : ""}`}>
                                            <li><a href="javascript:void(0);" className={selectedTab === "all" ? "active" : ""} onClick={() => { setSelectedTab('all'); setCurrentpage(1); setTabStatus('') }}>all ({allCount && allCount.all})</a></li>
                                            <li><a href="javascript:void(0);" className={selectedTab === "approved" ? "active" : ""} onClick={() => { setSelectedTab('approved'); setCurrentpage(1); setTabStatus('accepted'); setPaymentStatus([]); setFilterStatus([]); }}>APPROVED({allCount && allCount.approved})</a></li>
                                            <li><a href="javascript:void(0);" className={selectedTab === "in-review" ? "active" : ""} onClick={() => { setSelectedTab('in-review'); setCurrentpage(1); setTabStatus('in-review'); setPaymentStatus([]); setFilterStatus([]); }}>IN-REVIEW ({allCount && allCount.inReview})</a></li>
                                            <li><a href="javascript:void(0);" className={selectedTab === "pending" ? "active" : ""} onClick={() => { setSelectedTab('pending'); setCurrentpage(1); setTabStatus('pendingPayment'); setPaymentStatus([]); setFilterStatus([]); }}>pending payment({allCount && allCount.pendingPayment})</a></li>
                                        </ul>
                                        <div className="inventorySearch schoolFilter campainFilter">
                                            <SearchInput
                                                searchInpuOpen={searchInpuOpen} //true/false
                                                clearSearchIcon={clearSearchIcon} //true/false
                                                setSearchInpuOpen={setSearchInpuOpen} //function=>true/false
                                                setSearchKeys={setSearchKeys} //function=>string
                                                searchKeys={searchKeys} //string
                                                setClearSearchIcon={setClearSearchIcon}//function=>true/false
                                                palaceholder='Campaign name'
                                                setCurrentpage={setCurrentpage}
                                            />
                                            {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") && <a href='javascript:void(0)' className={`downloadSvg ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} style={{marginLeft: '10px'}} onClick={getExportableData1} ><img src={downloadSvg} /></a>}

                                            {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") && <a href='javascript:void(0)' className={`downloadSvg ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} style={{marginLeft: '10px' ,display:"none"}} onClick={getAllCampaignDetailsPDF} ><img src={downloadSvg} /></a>}

                                            {/* {(context.user.role === "backendAdmin" || context.user.role === "backendTech" || context.user.role === "backendOperations") && <a href="javascript:void(0);" className="btn btn-orange spaceL" onClick={getCampaignOverlay}>Campaign Overlay</a>} */}
                                            <div className="forminpt-dateFilter customRelative">
                                                <SingleSelect
                                                    formData={formData}
                                                    formKey='dateFilter'
                                                    onDataChange={onCategoryChange}
                                                    placeholder='Schedule Date'
                                                    isRequired={false}
                                                    options={config.monthFilterSheduleDate}
                                                    noScroll={true}
                                                />
                                                {formSheduledDataEmpty && <h1 className="valError">
                                                    <img src={errorSVG} />
                                                    {'Date required.'}
                                                </h1>
                                                }
                                                {customSheduledFilter && <div className={`filterDropDown tableFilter custom-w open`}>
                                                    <div className="filterDropBody">
                                                        <div className="filterDropHead">
                                                            <a href="javascript:void(0);" className="viewwLink" onClick={() => setCustomSheduledFilter(false)} ><img src={closeCustome} /></a>
                                                        </div>
                                                        <div className="filterDropClose open">
                                                            <div className="filtercheckbox">
                                                                <CustomDatePicker
                                                                    isChrome={isChrome}
                                                                    formData={isChrome ? formSheduledData : formSheduledDataDiffFormat}
                                                                    formKey='startSheduledDate'
                                                                    onDataChange={(e,v) => {onScheduleDateChange(e,v); setSheduleddFilterErr({ ...sheduledFilterErr, startDate: false }); setSheduledFilterErrMsg({ ...sheduledFilterErrMsg, startDateMsg: "" }) }}
                                                                    isRequired={false}
                                                                    formValidator={formValidator}
                                                                    validation='required'
                                                                    max={(formSheduledData.endSheduledDate && moment(formSheduledData.endSheduledDate).subtract(1, "days").clone().format("YYYY-MM-DD"))}
                                                                />
                                                                {sheduledFilterErr.startDate && <span className="valError"><img src={errorSVG} />{sheduledFilterErrMsg.startDateMsg}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="filterDropClose open">
                                                            <div className="filtercheckbox">
                                                                {/* </div> */}
                                                                <CustomDatePicker
                                                                    isChrome={isChrome}
                                                                    formData={isChrome ? formSheduledData : formSheduledDataDiffFormat}
                                                                    formKey='endSheduledDate'
                                                                    onDataChange={(e,v) => { onScheduleDateChange(e,v); setSheduleddFilterErr({ ...sheduledFilterErr, endDate: false }); setSheduledFilterErrMsg({ ...sheduledFilterErrMsg, endDateMsg: "" }) }}
                                                                    isRequired={false}
                                                                    formValidator={formValidator}
                                                                    validation='required'
                                                                    min={(formSheduledData.startSheduledDate && moment(formSheduledData.startSheduledDate).add(1, "days").clone().format("YYYY-MM-DD")) || moment().add(11, "days").clone().format("YYYY-MM-DD")}
                                                                />
                                                                {sheduledFilterErr.endDate && <span className="valError"><img src={errorSVG} />{sheduledFilterErrMsg.endDateMsg}</span>}
                                                                </div>
                                                        </div>
                                                                <div className="filterDropHead-campaign">
                                                                    <a href="javascript:void(0);" className="viewwLink" onClick={() => { setFormSheduledData({ startSheduledDate: "", endSheduledDate: "" }); setFormSheduledDataDiffFormat({startSheduledDate: "", endSheduledDate: "" }); setFlag(!flag); }} >Clear</a>
                                                                </div>
                                                                {formSheduledData.startSheduledDate!=='' && formSheduledData.endSheduledDate!=='' && <div className="filterDropHead-campaign">
                                                                    <a href="javascript:void(0);" className="viewwLink" onClick={() => setScheduleSearch(true) } >Search</a>
                                                                </div>}
                                                                

                                                    </div>
                                                </div>}
                                            </div>

                                            <div className="forminpt-dateFilter">
                                                <SingleSelect
                                                    formData={formData}
                                                    formKey='dateCreatedFilter'
                                                    onDataChange={onCategoryChange2}
                                                    placeholder='Created Date'
                                                    isRequired={false}
                                                    options={config.monthFilter}
                                                />
                                                {formCreatedDataEmpty && <h1 className="valError">
                                                    <img src={errorSVG} />
                                                    {'Date required.'}
                                                </h1>
                                                }

                                                {customCreatedFilter && <div className={`filterDropDown r-auto tableFilter custom-w open`}>
                                                    <div className="filterDropBody">
                                                        <div className="filterDropHead">
                                                            <a href="javascript:void(0);" className="viewwLink" onClick={() => setCustomCreatedFilter(false)} ><img src={closeCustome} /></a>
                                                        </div>
                                                        <div className="filterDropClose open">
                                                            <div className="filtercheckbox">
                                                                    <CustomDatePicker
                                                                    isChrome={isChrome}
                                                                    formData={isChrome ? formCreatedData : formCreatedDataDiffFormat}
                                                                    formKey='startCreatedDate'
                                                                    onDataChange={(e,v) => { onCreatedDateChange(e,v) ; setCreatedFilterErr({ ...createedFilterErr, startDate: false }); setCreatedFilterErrMsg({ ...createedFilterErrMsg, startDateMsg: "" }) }}
                                                                    isRequired={false}
                                                                    formValidator={formValidator}
                                                                    validation='required'
                                                                    // min={formCreatedData.endCreatedDate && moment(formCreatedData.endCreatedDate).subtract(1, "days").clone().format("YYYY-MM-DD")}
                                                                    max={formCreatedData.endCreatedDate ? moment(formCreatedData.endCreatedDate).subtract(1, "days").clone().format("YYYY-MM-DD") : moment().subtract(1, "days").clone().format("YYYY-MM-DD")}
                                                                    />
                                                                {createedFilterErr.startDate && <span className="valError"><img src={errorSVG} />{createedFilterErrMsg.startDateMsg}</span>}
                                                                {/* </div> */}
                                                                </div>
                                                        </div>
                                                        <div className="filterDropClose open">
                                                            <div className="filtercheckbox">
                                                                <CustomDatePicker
                                                                    isChrome={isChrome}
                                                                    formData={isChrome ? formCreatedData : formCreatedDataDiffFormat}
                                                                    formKey='endCreatedDate'
                                                                    onDataChange={(e, v) => { onCreatedDateChange(e, v); setCreatedFilterErr({ ...createedFilterErr, endDate: false }); setCreatedFilterErrMsg({ ...createedFilterErrMsg, endDateMsg: "" }) }}
                                                                    isRequired={false}
                                                                    formValidator={formValidator}
                                                                    validation='required'
                                                                    min={moment(formCreatedData.startCreatedDate).add(1, "days").clone().format("YYYY-MM-DD")}
                                                                    max={moment().clone().format("YYYY-MM-DD")}
                                                                />
                                                                {createedFilterErr.endDate && <span className="valError"><img src={errorSVG} />{createedFilterErrMsg.endDateMsg}</span>}
                                                                </div>
                                                        </div>
                                                                <div className="filterDropHead-campaign">
                                                                    <a href="javascript:void(0);" className="viewwLink" onClick={() => { setFormCreatedData({ startCreatedDate: "", endCreatedDate: "" }); setFormCreatedDataDiffFormat({ startCreatedDate: "", endCreatedDate: "" }); setFlag(!flag); }} >clear</a>
                                                                </div>
                                                                {formCreatedData.startCreatedDate!=='' && formCreatedData.endCreatedDate!=='' && <div className="filterDropHead-campaign">
                                                                    <a href="javascript:void(0);" className="viewwLink" onClick={() => setCreateSearch(true) } >Search</a>
                                                                </div>}
                                                                

                                                    </div>
                                                </div>}
                                            </div>
                                            <div className="filterDropHead-campaign">
                                                <a href="javascript:void(0);" className="viewwLink" onClick={() => { setCustomSheduledFilter(false); setCustomCreatedFilter(false); setFormData({ dateFilter: "", dateCreatedFilter: "" }); setFormSheduledData({startSheduledDate:"",endSheduledDate:""}); setFormSheduledDataDiffFormat({startSheduledDate: "", endSheduledDate: "" }); setFormCreatedData({ startCreatedDate: "", endCreatedDate: "" }); setFormCreatedDataDiffFormat({ startCreatedDate: "", endCreatedDate: "" }); setFlag(!flag); }} >Clear</a>
                                            </div>

                                        </div>
                                    </>
                                }
                                <CampaignTable
                                    campaigns={campaigns}
                                    setCategory={setCategory}
                                    setRegion={setRegion}
                                    setStates={setStates}
                                    setLocation={setLocation}
                                    category={category}
                                    region={region}
                                    states={states}
                                    location={location}
                                    setFilterType={setFilterType}
                                    filterType={filterType}
                                    setPaymentStatus={setPaymentStatus}
                                    paymentStatus={paymentStatus}
                                    onPageChange={onPageChange}
                                    onNextClick={onNextClick}
                                    onPrevClick={onPrevClick}
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    selectedTab={selectedTab}
                                    acceptRejectPayment={acceptRejectPayment}
                                    setFilterStatus={setFilterStatus}
                                    filterStatus={filterStatus}
                                    citiesByState={citiesByState}
                                    setCitiesByState={setCitiesByState}
                                    stateByRegion={stateByRegion}
                                    setStateByRegion={setStateByRegion}
                                    setOpenShoolFilter={setOpenShoolFilter}
                                    openShoolFilter={openShoolFilter}
                                    setOpenStatusFilter={setOpenStatusFilter}
                                    openStatusFilter={openStatusFilter}
                                    setOpenPaymentFilter={setOpenPaymentFilter}
                                    openPaymentFilter={openPaymentFilter}
                                    isLoading={isLoading}
                                    flag={flag}
                                    allCount={allCount?.all}
                                    onlyActiveCampaign={onlyActiveCampaign}
                                    schoolId={schoolId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CampaignRejetionModal
                openRejectedModal={openRejectedModal}
                hideModal={hideModal}
                rejectionMsg={rejectionMsg}
                setRejectionMsg={setRejectionMsg}
                formValidator={formValidator}
                rejectPayment={rejectPayment}
                loading={rejectingCampaign}
            />

        </>
    )
}


export default CampaignList
