import { AppContext } from "../../common/context/common";
import { Validator } from '../../common/utils/validator.js';
import { Utilities } from '../../common/utils/utilities';

export class BackendFOSContextInitiator {
    utils: Utilities;
    constructor(private appContext: AppContext){ this.utils = new Utilities(); }
    async getCommentsForCampaignReport (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'imageId', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportingComments->getCommentsForCampaignReport', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAssignedCampaignsByFOS (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'fosId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'campaignType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'dateFilter', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'status', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'paymentStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'campaignStatus', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignSchoolAssignments->getAssignedCampaignsByFOS', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAssignedSchoolsForFOS (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'state', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getAssignedSchoolsForFOS', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllCampaignReportingSetByCampaign (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'schoolId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReporting->getAllCampaignReportingSetByCampaign', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getApprovedImagesFromCampaignReports (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'groupBy', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReporting->getApprovedImagesFromCampaignReports', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getApprovedImagesFromCampaignReportAlbum (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'groupBy', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportAlbum->getApprovedImagesFromCampaignReportAlbum', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSetsFromCampaignReports (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'setApprovalStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schools', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReporting->getSetsFromCampaignReports', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignReportingAlbum (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportAlbum->getCampaignReportingAlbum', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignReportingAlbumsByCampaign (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportAlbum->getCampaignReportingAlbumsByCampaign', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getLoggedInUser (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getLoggedInUser', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolGeneralDetailsBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolGeneralDetailsBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolNameWithUserDetails (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolNameWithUserDetails', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolAmenitiesDetailsBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolAmenitiesDetailsBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllCampaignSchools (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'state', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolStatus', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getAllCampaignSchools', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolsByFOS (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'category', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'inventoryStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'locations', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosEmail', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosPhone', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosUserId', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolsByFOS', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolsRejectingMultipleCampaigns (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosEmail', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosPhone', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosUserId', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolsRejectingMultipleCampaigns', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllBrandsForExport (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'documents', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'brandType', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->getAllBrandsForExport', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllUserNotification (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->getAllUserNotification', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUserNotifications (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'timestamp', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->getUserNotifications', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllUserLocation (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'ipAddress', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->getAllUserLocation', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignsBySchoolExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'schoolId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'schoolStatus', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->getCampaignsBySchoolExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUsersToolTipProgress (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getUsersToolTipProgress', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolInventoriesToBeAddedExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'statusDiff', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getSchoolInventoriesToBeAddedExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getActivitiesExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->getActivitiesExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolActivitiesToBeAddedExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->getSchoolActivitiesToBeAddedExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandCategoryRecommendation (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'brandCategoryId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandCategoryRecommendation->getBrandCategoryRecommendation', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async addCommentForCampaignReport (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'setId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'imageId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'comment', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'userRole', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'commentBy', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportingComments->addCommentForCampaignReport', {data: newData});
        } else {
            return null;
        }
    }
    async deleteCommentForCampaignReport (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'setId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'imageId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'comment', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'userRole', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'commentBy', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportingComments->deleteCommentForCampaignReport', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addCampaignReportingSet (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'schoolId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'campaignId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'setApprovalStatus', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'inventories', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'events', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'location', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'latLng', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'setNo', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'setTitle', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReporting->addCampaignReportingSet', {data: newData});
        } else {
            return null;
        }
    }
    async updateCampaignReportingSet (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'setApprovalStatus', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'inventories', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'events', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'location', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'latLng', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReporting->updateCampaignReportingSet', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addCampaignReportingAlbum (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'title', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'setIds', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'campaignId', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'images', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'publishedToBrand', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportAlbum->addCampaignReportingAlbum', {data: newData});
        } else {
            return null;
        }
    }
    async updateCampaignReportingAlbum (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'title', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'setIds', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'publishedToBrand', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportAlbum->updateCampaignReportingAlbum', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async removeCampaignReportAlbum (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportAlbum->removeCampaignReportAlbum', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async createUserNotification (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->createUserNotification', {data: newData});
        } else {
            return null;
        }
    }
    async readAllUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->readAllUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async unreadUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->unreadUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async removeUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->removeUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async muteUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->muteUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateReadStateOfUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->updateReadStateOfUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addUserLocation (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'operatingSystem', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ipAddress', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'userId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->addUserLocation', {data: newData});
        } else {
            return null;
        }
    }
    async deleteUserLocation (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->deleteUserLocation', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateBackendUserById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'username', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'countryCode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'region', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'salesAdmin', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateBackendUserById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateUserDetails (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'username', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateUserDetails', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async changeUserPassword (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'password', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'oldPasswords', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->changeUserPassword', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateSchoolDetails (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->updateSchoolDetails', {data: data});
        } else {
            return null;
        }
    }
    async addUserForBackend (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->addUserForBackend', {data: data});
        } else {
            return null;
        }
    }
}
